<div class="container">

  <form class="search" [formGroup]="searchForm">

    <div>
      <mat-form-field>
        <input matInput placeholder="lidNr" formControlName="lidNr">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="voornaam" formControlName="firstName">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="achternaam" formControlName="surName">
      </mat-form-field>

    </div>

    <div>
      <mat-form-field>
        <input matInput placeholder="email" formControlName="email">
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="year" placeholder="jaar">
          <mat-option [value]=""> -</mat-option>
          <mat-option *ngFor="let year of possibleYears" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="typePilot" placeholder="piloot">
          <mat-option [value]="''"> - </mat-option>
          <mat-option [value]="'Mono'"> MONO </mat-option>
          <mat-option [value]="'Enkel lidgeld'"> Enkel lidgeld </mat-option>
          <mat-option [value]="'Duo'"> DUO </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button style="margin-right: 3px" (click)="searchFiche()">Zoek</button>
      <button color="accent" mat-raised-button (click)="reset()">Reset</button>

    </div>

  </form>

  <div *ngIf="showDetails && userDetails">
    <mat-tab-group dynamicHeight style="margin-bottom: 10px">
      <mat-tab label="Gegevens"> <app-gegevens-details [userDetails]="userDetails"></app-gegevens-details> </mat-tab>
      <mat-tab label="Vergunning"> <app-vergunning-details [userDetails]="userDetails"></app-vergunning-details> </mat-tab>
      <mat-tab label="Verzekering"> <app-verzekering-details [userDetails]="userDetails"></app-verzekering-details> </mat-tab>
      <mat-tab label="Airmiles"> <app-airmiles-details [userDetails]="userDetails"></app-airmiles-details> </mat-tab>
      <mat-tab label="Schermen"> <app-schermen-details [userDetails]="userDetails"></app-schermen-details> </mat-tab>
    </mat-tab-group>

  </div>

  <div class="result" *ngIf="!showDetails && searchResults?.length">

    <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="lidNr">
          <th mat-header-cell *matHeaderCellDef> Lid nr.</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.lidNr}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> Voornaam</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surName">
          <th mat-header-cell *matHeaderCellDef> Naam</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.surName}} </td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef> Straat</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.street}} </td>
        </ng-container>

        <ng-container matColumnDef="nr">
          <th mat-header-cell *matHeaderCellDef> Nr</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.nr}} </td>
        </ng-container>

        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef> Postcode</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.postalCode}} </td>
        </ng-container>

        <ng-container matColumnDef="municipal">
          <th mat-header-cell *matHeaderCellDef> Gemeente</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.municipal}} </td>
        </ng-container>


        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef> Land</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.country}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email</th>
          <td mat-cell *matCellDef="let fiche"> {{fiche.email}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let element;">
            <button mat-raised-button style="cursor: pointer; margin-right: 3px" color="primary" (click)="showQr(element)">QR</button>
            <mat-icon style="cursor: pointer; margin-right: 3px" color="primary" (click)="openDetails(element)">update</mat-icon>
            <mat-icon style="cursor: pointer" color="warn" (click)="openDialog(element)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </div>

</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
