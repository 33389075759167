<div class="request-reset">
  <div class="container">
    <div class="title" style="margin-bottom: 3%">
      <h3>Wijzig wachtwoord</h3>
    </div>
    <div class="panel-body" style="padding-bottom: 2em">
      <form class="form-layout" [formGroup]="form" (ngSubmit)="resetPasswordEmail()">
        <mat-form-field>
          <input style="width: 100%;" matInput placeholder="Email" formControlName="email" required email>
        </mat-form-field>

        <button style="width: 25%; cursor: pointer" mat-raised-button type="submit" [disabled]="!form.valid">Reset</button>
      </form>
    </div>
  </div>
  <div class="pic">
  </div>
</div>
