import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {DashboardComponent} from "./dashboard.component";
import {ProfileComponent} from './public/profile/profile.component';
import {InfoComponent} from './links/info/info.component';
import {MatCardModule} from "@angular/material/card";
import {EmailComponent} from './admin/email/email.component';
import {SchermenComponent} from './public/schermen/schermen.component';
import {FlybookComponent} from './public/flybook/flybook.component';
import {TerrainComponent, TerreinDialogComponent} from './public/terrain/terrain.component';
import {AddMemberComponent} from './admin/add-member/add-member.component';
import {SearchFicheComponent} from './admin/search-fiche/search-fiche.component';
import {AirmilesComponent} from './admin/airmiles/airmiles.component';
import {SearchSchermComponent} from './admin/search-scherm/search-scherm.component';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {OverlaySpinnerComponent} from "./overlay-spinner.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ConfirmationDialogComponent} from "./admin/dialog/confirmation-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTabsModule} from "@angular/material/tabs";
import {GegevensDetailsComponent} from './admin/search-fiche/details/gegevens-details/gegevens-details.component';
import {VergunningDetailsComponent} from './admin/search-fiche/details/vergunning-details/vergunning-details.component';
import {
  VerzekeringDetailsComponent
} from './admin/search-fiche/details/verzekering-details/verzekering-details.component';
import {AirmilesDetailsComponent} from './admin/search-fiche/details/airmiles-details/airmiles-details.component';
import {SchermenDetailsComponent} from './admin/search-fiche/details/schermen-details/schermen-details.component';
import {QrCodeComponent} from "./qrcode/qr-code.component";
import {LidkaartComponent} from "./lidkaart/lidkaart.component";
import {AttestComponent} from "./attest/attest.component";
import {DocumentsComponent} from "./public/documents/documents.component";
import {NgxMaskModule} from "ngx-mask";
import {AviabelUserComponent} from "./admin/aviabel-user/aviabel-user.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {AanmeldingenComponent} from "./admin/aanmeldingen/aanmeldingen.component";

@NgModule({
  declarations: [
    DashboardComponent,
    ProfileComponent,
    InfoComponent,
    EmailComponent,
    SchermenComponent,
    FlybookComponent,
    TerrainComponent,
    AddMemberComponent,
    SearchFicheComponent,
    AirmilesComponent,
    SearchSchermComponent,
    OverlaySpinnerComponent,
    ConfirmationDialogComponent,
    GegevensDetailsComponent,
    VergunningDetailsComponent,
    VerzekeringDetailsComponent,
    AirmilesDetailsComponent,
    SchermenDetailsComponent,
    AviabelUserComponent,
    QrCodeComponent,
    LidkaartComponent,
    AttestComponent,
    DocumentsComponent,
    TerreinDialogComponent,
    AanmeldingenComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTabsModule,
    NgxMaskModule,
    MatTooltipModule
  ],
  exports: [OverlaySpinnerComponent]
})
export class DashboardModule {
}
