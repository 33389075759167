<div class="reset">
  <div class="container">
    <div class="title" style="margin-bottom: 3%">
      <h3>Lidnr opvragen</h3>
    </div>
    <div class="panel-body" style="padding-bottom: 2em">
      <form class="form-layout" [formGroup]="form" (ngSubmit)="getLidnrByEmail()">
        <mat-form-field>
          <input style="width: 100%;" matInput placeholder="Email" formControlName="email" required email>
        </mat-form-field>

        <button style="width: 25%; cursor: pointer" mat-raised-button type="submit" [disabled]="!form.valid">Zoek</button>
      </form>
    </div>
    <div class="panel-body" style="padding-bottom: 0.5em">
      <a *ngIf="response && response.lidNr != 0">Uw lidnr is: {{ response.lidNr }}</a>
      <a *ngIf="response && response.lidNr == 0">Geen lidnr gevonden voor opgegeven emailadres!</a>
    </div>
  </div>
  <div class="pic">
  </div>
</div>
