import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../../model/user";
import {AdminService} from "../../../../../../service/admin.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {LoginService} from "../../../../../../service/login.service";
import {ToastrService} from "ngx-toastr";
import {Airmiles} from "../../../../../model/airmiles";
import {TokenStorageService} from "../../../../../../service/token-storage.service";

@Component({
  selector: 'app-airmiles-details',
  templateUrl: './airmiles-details.component.html',
  styleUrls: ['./airmiles-details.component.scss']
})
export class AirmilesDetailsComponent implements OnInit {

  @Input()
  userDetails: User;

  loading: boolean = false;

  displayedColumns = ['airmiles', 'info', 'year', 'madeBy', 'dateCreated', 'actionsColumn']

  registerForm: FormGroup;

  constructor(private adminService: AdminService,
              private tokenStorageService: TokenStorageService,
              private loginService: LoginService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      id: [''],
      airmiles: [''],
      airmilesInfo: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      lidNr: [this.userDetails?.lidNr]
    });
  }

  updateUser() : void {
    this.loading = true;
    this.adminService.getFullMember(this.userDetails.lidNr).subscribe( (res) => {
      this.loading = false;
      this.userDetails = res;

      this.registerForm = this.formBuilder.group({
        id: [''],
        airmiles: [''],
        airmilesInfo: [''],
        adjustedBy: [this.tokenStorageService.getUser().username],
        lidNr: [this.userDetails?.lidNr]
      });

      this.toastr.success("Airmiles bijgewerkt!")
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message);
    })
  }

  deleteAirmile(element: any) {
    this.adminService.deleteAirmiles(element.id).subscribe( () => this.updateUser(), error => this.toastr.error(error.error.message));
  }

  addAirmile() {
    const newAirmile: Airmiles = this.registerForm.value;
    this.adminService.addAirmiles(newAirmile).subscribe( () => this.updateUser(), error => this.toastr.error(error.error.message));
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
  }

  reset() {
    this.registerForm = this.formBuilder.group({
      id: [''],
      airmiles: [''],
      airmilesInfo: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      lidNr: [this.userDetails?.lidNr]
    });
  }
}
