<div *ngIf="!user">
 <p>Geen verzekeringen gevonden voor {{currentYear}}!</p>
</div>

<div *ngIf="user">
  <div>
    <button mat-raised-button (click)="download()">Download</button>
  </div>

  <div id="attest">
    <table width="700" border="10" cellspacing="0" cellpadding="0">
      <tr>
        <td>
          <table width="650" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td width="38%">
                <div align="center"><img src="assets/images/aviabel.gif" alt="..." width="302" height="150"/></div>
              </td>
              <td width="33%">&nbsp;</td>
              <td width="29%">
                <div align="center"><img src="assets/images/pmsa.jpg" alt="..." width="400" height="200"/></div>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <td colspan="3" align="center">
                <table width="400" border="5" cellspacing="0" cellpadding="0">
                  <tr>
                    <td>
                      <div align="center"><strong>VERZEKERINGSATTEST, CERTIFICAT D'ASSURANCE
                        <br/>PARAMOTOR, PARAMOTEUR</strong></div>
                    </td>
                  </tr>
                </table>
                <div align="center"></div>
              </td>
            </tr>
            <tr>
              <td colspan="3" align="center"><p>&nbsp;</p></td>
            </tr>
            <tr>
              <td colspan="3" align="center">
              <span class="style1">
              Verklaart dat onderstaand lid of leerling met nummer verzekerd is bij <strong>AVIABEL </strong>
              <br> met het polisnummer <strong>14.022.104</strong> voor de volgende aktiviteit(en):
                <br> Certifieque le membre dont le nom et le numéro figurent ci dessous est assuré par le <br>contrat <strong>AVIABEL </strong> police
                numéro <strong>14.022.104</strong> pour la/les activités suivantes:
              </span>
              </td>
            </tr>

            <tr>
              <td colspan="3" align="center"><p>&nbsp;</p>
                <table width="400" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <div align="center" *ngFor="let insurance of currentInsurances">
                      <td align="center"><span align="center"
                                               class="style6">{{insurance.verzekeringBpmf}}</span>
                      </td>
                    </div>
                  </tr>
                </table>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td colspan="3"><p
                class="style1">Dit verzekeringsattest is afgeleverd als  bewijs voor bovenvermelde dekking, en wordt enkel afgeleverd indien de betaling  werd uitgevoerd. <br> Ce certificat d'assurance est délivré à titre de preuve de l'existence du contrat d'assurance mentionné ci-dessus et n'est délivré qu'après payement du montant requis.</p>
                <p class="style1">Alle voorwaarden, beperkingen en  uitsluitingen van het contract zijn terug te vinden op de site van de pmsa (http://www.PMSA.be). <br> Toutes les conditions, limitations et exclusions du contrat sont mentionnées sur le site de la fédération (www.federationparamoteur.be) , rubrique assurances.</p>
                <p class="style1">De lezer van dit attest kan ten allen tijde de legitimiteit controleren door de QR code van de kaarthouder in te scannen. <br> Le lecteur de ce certificat peut à tout moment vérifier la légitimité en scannant le code QR du titulaire</p>
              </td>
            </tr>
            <tr>
              <td colspan="3" align="center">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3" align="center">
                <p align="center" class="style1">Aanvang van het kontrakt: 1 januari {{currentYear}} om 0 uur. <br> Prise en cours du contrat : le premier janvier {{currentYear}} à 0 heure.
                  <br/>
                  Jaarlijkse vervaldag : 1 januari om 0 uur. <br> Échéance annuelle: le premier janvier à 0 heure.
                </p></td>
            </tr>
            <tr>
              <td colspan="3" align="center"><p align="center">&nbsp;</p>
                <table width="400" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="250" align="left" valign="top"><span
                      class="style4">Lidnummer, N° de membre</span></td>
                    <td width="150" align="left" valign="top">{{user?.lidNr}}</td>
                  </tr>
                  <tr>
                    <td align="left" valign="top"><span class="style4">Naam, Nom</span></td>
                    <td align="left" valign="top">{{user?.surName}}</td>
                  </tr>
                  <tr>
                    <td align="left" valign="top"><span
                      class="style4">Voornaam, Prénom</span></td>
                    <td align="left" valign="top">{{user?.firstName}}</td>
                  </tr>
                  <tr>
                    <td align="left" valign="top"><span class="style4">Adres, Addresse</span>
                    </td>
                    <td align="left" valign="top">
                      {{user.street}} {{user.nr}}
                      <br>
                      {{user.postalCode}} {{user.municipal}}
                    </td>
                  </tr>
                </table>
                <div align="center"></div>
              </td>
            </tr>
            <br>
            <tr>
              <td colspan="3" align="center">
                <table width="500" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="23%">&nbsp;</td>
                    <td width="44%">&nbsp;</td>
                    <td
                      width="33%">In opdracht van het bestuur <br> Au nom du conseil d'administration</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td><img src="assets/images/handtek-verz-attest.jpg" width="116" height="76"/>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>
