import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from "../../service/token-storage.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isAdmin: boolean;

  constructor(private tokenStorageService: TokenStorageService) {
  }

  ngOnInit(): void {
    let roles: string[] = this.tokenStorageService.getUser().roles;
    this.isAdmin = roles.includes('ROLE_ADMIN')
  }

  logout() {
    this.tokenStorageService.signOut();
  }
}
