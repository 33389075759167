<div>
  <tr>
    <td align="center"><a href="http://www.pmsa.be"><img src="assets/images/pmsa.jpg" width=200></a></td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">PILOT NAME</font></td>
  </tr>

  <tr>
    <td align="center"><p style="color:black;font-size:23px;">{{user?.surName}} {{user?.firstName}}</p></td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">PILOT PICTURE</font></td>
  </tr>

  <tr>
    <td align="center">
      <font color="black">
        <img [src]="avatar" *ngIf="avatar" style="border-radius: 20px; border: 1px solid black" width="150px"
             height="200px">
        <img src="assets/images/unknown.jpg" *ngIf="!avatar" style="border-radius: 20px; border: 1px solid black"
             width="150px" height="200px">
      </font>
    </td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">IN CASE OF EMERGENCY CALL</font></td>
  </tr>
  <tr>
    <td align="center"><p style="color:red;font-size:23px;">{{user?.iceNumber}}</p></td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">ADDRESS</font></td>
  </tr>
  <tr>
    <td align="center">
      <font color="black">
        {{user?.street}} {{user?.nr}}
        <br>
        {{user?.postalCode}} {{user?.municipal}}
      </font>
    </td>
  </tr>

  <tr>
    <td align="center" bgcolor=black>
      <font color="white">PMSA STATUS <strong><br>{{year}}</strong>
      </font>
    </td>
  </tr>
  <tr>
    <td align="center">{{user?.memberStatus}}</td>
  </tr>

  <tr>
    <td align="center" bgcolor=black>
      <font color="white">INSURANCE STATUS <br><strong>{{year}}</strong>
    </font>
    </td>
  </tr>
  <tr>
    <td align="center">{{user?.insuranceStatus}}</td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">PMSA KNOWN PARAMOTOR LICENSES</font>
      <br><font color="white">(Please check official documents for validation)</font>
    </td>
  </tr>
  <tr>
    <div align="center" *ngFor="let license of user?.licenses">
      <td align="center">{{license.licence}} ({{license.remarks}})</td>
    </div>

    <br *ngIf="user?.licenses?.length === 0">
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">PMSA AIRMILES<br><strong>{{year}}</strong>
    </font>
    </td>
  </tr>
  <tr>
    <td align="center"><p
      style="color:green;font-size:23px;">{{user?.totalAirmilesCurrentYear}}</p></td>
  </tr>

  <tr>
    <td align="center" bgcolor=black><font color="white">PMSA AIRMILES<br><strong>TOTAL</strong> </font>
    </td>
  </tr>
  <tr>
    <td align="center"><p style="color:green;font-size:23px;">{{user?.totalAirmiles}}</p>
    </td>
  </tr>

  <tr>
    <td align="center" bgcolor=black>
      <font color="white">DATA EXTRACTED FROM <br>PMSA DATABASE ON</font>
    </td>
  </tr>
  <tr>
    <td align="center">
      {{now}}
    </td>
  </tr>
  <tr>
    <td align="center" bgcolor=black>
      <font color="white">QR</font>
    </td>
  </tr>
  <tr>
    <td align="center">
      <img id="image3" [src]="qrcode" alt="..." width=84px/>
    </td>
  </tr>

</div>
