import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {PublicService} from "../../service/public.service";
import {RequestLidNr} from "../model/request-lidnr";

@Component({
  selector: 'app-request-lidnr',
  templateUrl: './request-lidnr.component.html',
  styleUrls: ['./request-lidnr.component.scss']
})
export class RequestLidnrComponent implements OnInit {

  form: FormGroup;
  response: RequestLidNr;

  constructor(private publicService: PublicService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  getLidnrByEmail() {
    this.publicService.getLidNr(this.form.controls.email.value).subscribe((lidnr) => {
      this.response = lidnr;
      console.log(this.response)
    }, (err) => this.toastr.error(err.message));
  }
}
