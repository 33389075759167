import {Component, OnInit} from "@angular/core";
import {AdminService} from "../../../service/admin.service";
import {ActivatedRoute} from "@angular/router";
import {User} from "../../model/user";
import {PmsaMember} from "../../model/pmsa-member";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


@Component({
  selector: 'app-attest',
  templateUrl: './attest.component.html',
  styleUrls: ['./attest.component.scss']
})
export class AttestComponent implements OnInit {

  user: User;
  currentYear = new Date().getFullYear();
  currentInsurances: PmsaMember[] = [];

  constructor(private adminService: AdminService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const lidNr = this.activatedRoute.snapshot.paramMap.get("lidnr");

    this.adminService.getAttest(lidNr).subscribe(res => {
      if(res) {
        this.user = res;
        this.currentInsurances = this.filterInsurances(res?.pmsaMemberList);
      }
    });
  }

  private filterInsurances(insurances: PmsaMember[]) {
    const currentInsurances = [];

    for (const insurance of insurances) {
      if(insurance.year === this.currentYear) {
        currentInsurances.push(insurance);
      }
    }

    return currentInsurances;
  }

  download() {
    const data = document.getElementById('attest');
    html2canvas(data).then(canvas => {
      const imgWidth = 210;
      const imgHeight = 297

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('attest.pdf');
    });
  }
}
