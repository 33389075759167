<div class="container" *ngIf="emails">

  <mat-form-field>
    <textarea matInput [(ngModel)]="emails"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="50">
    </textarea>
  </mat-form-field>

</div>
