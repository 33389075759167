<div class="container">

  <form class="search" [formGroup]="searchForm">

    <div>

      <mat-form-field>
        <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" required  placeholder="Aangemeld van" formControlName="aangemeldVan">
      </mat-form-field>

      <mat-form-field>
        <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']"  placeholder="Aangemeld tot" formControlName="aangemeldTot">
      </mat-form-field>

    </div>

    <div>
      <button mat-raised-button style="margin-right: 1.5px" (click)="searchAanmeldingen()">Zoek</button>
      <button mat-raised-button *ngIf="searchResults?.length" (click)="downloadAanmeldingen()">Download</button>
    </div>

  </form>

  <div class="result" *ngIf="searchResults?.length">

    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="aangemeldOp">
          <th mat-header-cell *matHeaderCellDef>Aangemeld op</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.aangemeldOp}} </td>
        </ng-container>

        <ng-container matColumnDef="lidNr">
          <th mat-header-cell *matHeaderCellDef>Lidnr</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.lidNr}} </td>
        </ng-container>

        <ng-container matColumnDef="naam">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.naam}} </td>
        </ng-container>

        <ng-container matColumnDef="gemeente">
          <th mat-header-cell *matHeaderCellDef>Email gemeente</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.gemeente}} </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef> Lengtegraad</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.longitude}} </td>
        </ng-container>

        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef> Breedtegraad</th>
          <td mat-cell *matCellDef="let aanmelding"> {{aanmelding.latitude}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
