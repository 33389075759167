<div class="login">
  <div class="container">
    <div class="title" style="margin-bottom: 1%">
      <h3>Welkom bij PMSA</h3>
    </div>
    <div class="panel-body">
      <form class="form-layout"
            [formGroup]="form" (ngSubmit)="login()">
        <mat-form-field>
          <input name="username" matInput placeholder="Lid nr." formControlName="username" required/>
        </mat-form-field>

        <mat-form-field>
          <input name="password" matInput placeholder="Wachtwoord" formControlName="password" [type]="hide ? 'password' : 'text'"
                 required/>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>

        <div style="padding-bottom: 2em; display: flex; flex-direction:column">
          <button name="login" style="margin-right: 1em; margin-bottom: 1em; width:3em" mat-raised-button type="submit" [disabled]="!form.valid">
            Login
          </button>
          <div style="display: flex; flex-direction: column">
            <a [routerLink]="['/request-password']">Wijzig wachtwoord</a>
            <a [routerLink]="['/request-lidnr']">Lidnr vergeten</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="pic">
  </div>

</div>


