import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "../login/login.component";
import {ResetPasswordComponent} from "../reset-password/reset-password.component";
import {RequestResetPasswordComponent} from "../request-reset-password/request-reset-password.component";
import {DashboardComponent} from "../dashboard/dashboard.component";
import {ProfileComponent} from "../dashboard/public/profile/profile.component";
import {InfoComponent} from "../dashboard/links/info/info.component";
import {EmailComponent} from "../dashboard/admin/email/email.component";
import {TerrainComponent} from "../dashboard/public/terrain/terrain.component";
import {FlybookComponent} from "../dashboard/public/flybook/flybook.component";
import {SchermenComponent} from "../dashboard/public/schermen/schermen.component";
import {AddMemberComponent} from "../dashboard/admin/add-member/add-member.component";
import {SearchSchermComponent} from "../dashboard/admin/search-scherm/search-scherm.component";
import {AirmilesComponent} from "../dashboard/admin/airmiles/airmiles.component";
import {SearchFicheComponent} from "../dashboard/admin/search-fiche/search-fiche.component";
import {QrCodeComponent} from "../dashboard/qrcode/qr-code.component";
import {LidkaartComponent} from "../dashboard/lidkaart/lidkaart.component";
import {AttestComponent} from "../dashboard/attest/attest.component";
import {DocumentsComponent} from "../dashboard/public/documents/documents.component";
import {AuthenticationGuard} from "../guard/authentication.guard";
import {AviabelUserComponent} from "../dashboard/admin/aviabel-user/aviabel-user.component";
import {RequestLidnrComponent} from "../request-lidnr/request-lidnr.component";
import {AanmeldingenComponent} from "../dashboard/admin/aanmeldingen/aanmeldingen.component";


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'request-password', component: RequestResetPasswordComponent},
  {path: 'request-lidnr', component: RequestLidnrComponent},
  {
    path: 'dashboard', component: DashboardComponent, children: [
      {path: '', redirectTo: 'profile', pathMatch: 'full'},
      {path: 'profile', component: ProfileComponent},
      {path: 'documents', component: DocumentsComponent},
      {path: 'schermen', component: SchermenComponent},
      {path: 'flybook', component: FlybookComponent},
      {path: 'terrain', component: TerrainComponent},
      {path: 'info', component: InfoComponent},
      {path: 'email', component: EmailComponent},
      {path: 'add-member', component: AddMemberComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'search-fiche', component: SearchFicheComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'aviabel-user', component: AviabelUserComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'airmiles', component: AirmilesComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'search-scherm', component: SearchSchermComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'aanmeldingen', component: AanmeldingenComponent, data: {roles: ['ROLE_ADMIN']}},
      {path: 'lidkaart/:lidnr/:guid', component: LidkaartComponent},
      {path: 'attest/:lidnr', component: AttestComponent},
    ]
    , canActivate: [AuthenticationGuard]
  },
  {path: 'qr/:lidnr/:guid', component: QrCodeComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
