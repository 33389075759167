import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../../model/user";
import {AdminService} from "../../../../../../service/admin.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../../../../../service/login.service";
import {ToastrService} from "ngx-toastr";
import {PmsaMember} from "../../../../../model/pmsa-member";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../../../../../service/token-storage.service";

@Component({
  selector: 'app-verzekering-details',
  templateUrl: './verzekering-details.component.html',
  styleUrls: ['./verzekering-details.component.scss']
})
export class VerzekeringDetailsComponent implements OnInit {

  @Input()
  userDetails: User;

  loading: boolean = false;

  possibleYears: number[] = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1]
  possibleVerzekeringen: string[] = ['Enkel lidgeld', 'Mono', 'Duo'];
  displayedColumns = ['year', 'lidgeld', 'verzekering_pmsa', 'premie', 'verzekering_andere', 'polis_nr', 'remarks', 'madeBy', 'dateValid', 'payed', 'attest', 'actionsColumn']

  registerForm: FormGroup;

  constructor(private adminService: AdminService,
              private loginService: LoginService,
              private tokenStorageService: TokenStorageService,
              private formBuilder: FormBuilder,
              private router: Router,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      year: ['', Validators.required],
      remark: [''],
      verzekeringBpmf: [''],
      aanmaakDatum: [''],
      lidGeld: [''],
      verzekeringsPremie: [''],
      verzekeringAndere: [''],
      verzekeringAnderePolisNr: [''],
      payed: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      lidNr: [this.userDetails?.lidNr]
    });
  }

  updateUser(): void {
    this.loading = true;
    this.adminService.getFullMember(this.userDetails.lidNr).subscribe((res) => {
      this.loading = false;
      this.userDetails = res;

      this.registerForm = this.formBuilder.group({
        year: ['', Validators.required],
        remark: [''],
        verzekeringBpmf: [''],
        aanmaakDatum: [''],
        lidGeld: [''],
        verzekeringsPremie: [''],
        verzekeringAndere: [''],
        verzekeringAnderePolisNr: [''],
        adjustedBy: [this.tokenStorageService.getUser().username],
        payed: [''],
        lidNr: [this.userDetails?.lidNr]
      });

      this.toastr.success("Verzekering bijgewerkt!")
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message);
    })
  }

  deleteVerzekering(element: any) {
    this.adminService.deleteVerzekering(element.id).subscribe(() => this.updateUser(), error => this.toastr.error(error))
  }

  addVerzekering() {
    const newVerzekering: PmsaMember = this.registerForm.value;
    this.adminService.addVerzekering(newVerzekering).subscribe(() => this.updateUser(), error => this.toastr.error(error))
  }

  showVerzekeringsAttest(element: any) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/attest/' + element.lidNr]));
    window.open(url, '_blank');
  }

  updateVerzekering(member) {
    this.adminService.updateMember(member).subscribe(() => this.updateUser(), error => this.toastr.error(error));
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
  }

  showAttestButton(member: PmsaMember) {
    return member.year == new Date().getFullYear() &&
    member.payed === true &&
    (member.verzekeringBpmf === 'Mono' || member.verzekeringBpmf === 'Duo');
  }

  reset() {
    this.registerForm = this.formBuilder.group({
      year: ['', Validators.required],
      remark: [''],
      verzekeringBpmf: [''],
      aanmaakDatum: [''],
      lidGeld: [''],
      verzekeringsPremie: [''],
      verzekeringAndere: [''],
      verzekeringAnderePolisNr: [''],
      payed: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      lidNr: [this.userDetails?.lidNr]
    });

  }

  updateLidgeld($event: any) {
    const value = $event.source.value;

    if (value === 'Enkel lidgeld') {
      this.registerForm.get('lidGeld').setValue(50);
    } else if (value === 'Mono') {
      this.registerForm.get('verzekeringsPremie').setValue(120);
    } else if (value === 'Duo') {
      this.registerForm.get('verzekeringsPremie').setValue(220);
    } else {
      this.registerForm.get('lidGeld').setValue(undefined);
      this.registerForm.get('verzekeringsPremie').setValue(undefined);
    }
  }
}
