import {Component, OnInit} from '@angular/core';
import {User} from "../../../model/user";
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {AdminService} from "../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment";

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  loading: boolean = false;
  profileForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      surName: ['', Validators.required],
      firstName: ['', Validators.required],
      birthDate: ['' ,Validators.required],
      birthPlace: ['', Validators.required],
      rrn: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      telNumber: [''],
      gsmNumber: [''],
      iceNumber: [''],
      street: ['', Validators.required],
      nr:['', Validators.required],
      postalCode:['', Validators.required],
      municipal:['', Validators.required],
      country:['', Validators.required],
      bestuursLid: [false]
    });
  }

  saveProfile(formDirective: FormGroupDirective) {
    if (this.profileForm.valid) {
      this.loading = true;
      const user: User = this.profileForm.value;

      this.adminService.userExists(user.email).subscribe( response => {
        if(response) {
          this.toastr.error("Gebruiker met emailadres bestaat al in de databank!")
          this.loading = false;
        } else {
          this.adminService.createUser(user).subscribe(() => {
            this.loading = false;
            formDirective.resetForm()
            this.profileForm.reset()
            this.toastr.success("Nieuw lid aangemaakt!")
          }, error => {
            this.loading = false;
            this.toastr.error(error.error.message)
          })
        }
      })
    }

  }

}
