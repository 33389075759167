<div *ngIf="userDetails" style="margin-top: 15px">

  <div class="person-details">
    <fieldset>
      <legend>Persoonsgegevens</legend>
      <div class="person">
        <mat-form-field>
          <input matInput readonly required placeholder="lidNr" [(ngModel)]="userDetails.lidNr">
        </mat-form-field>

        <mat-form-field>
          <input matInput required placeholder="naam" [(ngModel)]="userDetails.surName">
        </mat-form-field>

        <mat-form-field>
          <input matInput required placeholder="voornaam" [(ngModel)]="userDetails.firstName">
        </mat-form-field>

        <mat-form-field>
          <input matInput required mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Geboortedatum" [(ngModel)]="userDetails.birthDate">
        </mat-form-field>

        <mat-form-field>
          <input matInput required placeholder="Geboorteplaats" [(ngModel)]="userDetails.birthPlace">
        </mat-form-field>

        <mat-form-field>
          <input matInput required placeholder="Email" [(ngModel)]="userDetails.email">
        </mat-form-field>

        <mat-form-field>
          <input matInput required mask="00.00.00-000.00" placeholder="Rrn" [(ngModel)]="userDetails.rrn">
        </mat-form-field>

        <mat-form-field>
          <mat-select [(ngModel)]="userDetails.paramotorType" placeholder="Type">
            <mat-option [value]=""> - </mat-option>
            <mat-option *ngFor="let brand of possibleParamotors" [value]="brand">
              {{brand}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Medisch gekeurd op(yyyy-mm-dd)" [(ngModel)]="userDetails.medicalCert">
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset>
      <legend>Foto</legend>
      <div class="person">
        <img [src]="avatar" *ngIf="avatar" style="border-radius: 20px; border: 1px solid black" width="171px" height="200px">
        <img src="assets/images/unknown.jpg" *ngIf="!avatar" style="border-radius: 15px; border: 1px solid black" width="171px" height="200px">

        <div style="display: flex; flex-direction: row; justify-content: space-evenly; margin-top: 10px">
          <input
            style="display: none"
            type="file" accept="image/*" (change)="onFileChanged($event)"
            #fileInput>
          <button mat-raised-button style="margin-right: 10px" (click)="fileInput.click()">Zoeken</button>
          <button mat-raised-button (click)="onUpload()">Opslaan</button>
        </div>

      </div>
    </fieldset>

  </div>

  <div style="margin-top: 10px;">
    <fieldset>
      <legend>Address</legend>
      <div class="address">
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput placeholder="Straat" [(ngModel)]="userDetails.street">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Nr" [(ngModel)]="userDetails.nr">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Postcode" [(ngModel)]="userDetails.postalCode">
          </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput placeholder="Gemeente" [(ngModel)]="userDetails.municipal">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Land" [(ngModel)]="userDetails.country">
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </div>

  <div style="margin-top: 10px;">
    <fieldset>
      <legend>Contact</legend>
      <div class="numbers">
        <mat-form-field>
          <input matInput placeholder="Tel" [(ngModel)]="userDetails.telNumber">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="GSM" [(ngModel)]="userDetails.gsmNumber">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="ICE" [(ngModel)]="userDetails.iceNumber">
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset style="margin-top: 10px;">
      <legend>Toelatingen</legend>
      <div class="permissions">
        <div>
          <mat-checkbox [(ngModel)]="userDetails.terreinMachtiging">Machtiging voor terreinregistratie
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="userDetails.bestuursLid">Bestuurslid
          </mat-checkbox>
        </div>
      </div>
    </fieldset>

  </div>

  <div style="margin-top: 10px; display: flex; justify-content: flex-end">
    <button color="primary" mat-raised-button style="margin-right: 5px" (click)="showMemberCard()">Lidkaart</button>
    <button color="accent" mat-raised-button (click)="updateUser()">Update</button>
  </div>

</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
