import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import {Scherm} from "../../../model/scherm";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchScherm} from "../../../model/search-scherm";

@Component({
  selector: 'app-search-scherm',
  templateUrl: './search-scherm.component.html',
  styleUrls: ['./search-scherm.component.scss']
})
export class SearchSchermComponent implements OnInit {

  displayedColumns = ['lidNr', 'firstName', 'surName', 'brand', 'idNumber', 'type', 'primaryColor', 'secondaryColor', 'tertiaryColor', 'm2', 'info', 'reclame', 'registrationDate']
  colors = ['BLAUW', 'BRUIN', 'GEEL', 'GRIJS', 'GROEN', 'ORANJE', 'ROOD', 'VIOLET', 'WIT', 'ZWART']

  possibleBrands: string[] = [];
  searchResults: Scherm[] = [];
  searchForm: FormGroup;

  loading: boolean = false;

  constructor(private adminService: AdminService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.adminService.getBrandSchermen().subscribe(res => this.possibleBrands = res);

    this.searchForm = this.formBuilder.group({
      brand: [''],
      type: [''],
      primaryColor: [''],
      otherColor: [''],
      info: [''],
      reclame: [''],
      idNumber: ['']
    });
  }

  searchScherm() {
    this.loading = true
    const searchCriteria: SearchScherm = this.searchForm.value;

    this.adminService.getSchermen(searchCriteria).subscribe(res => {
      this.searchResults = res
      this.loading = false;

      if (res?.length === 0) {
        this.toastr.warning("Geen resultaten gevonden!")
      }
    }, error => {
      this.toastr.error(error.error.message)
      this.loading = false;
    })
  }

}
