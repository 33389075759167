<div class="link-container">
  <mat-card class="link-card">
    <mat-card-header (click)="goToMeteo($event)">
      <div mat-card-avatar class="card-header-image-meteo"></div>
      <mat-card-title>Meteo</mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-card class="link-card">
    <mat-card-header (click)="goToPmsa($event)">
      <div mat-card-avatar class="card-header-image-pmsa"></div>
      <mat-card-title>PMSA</mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-card class="link-card">
    <mat-card-header (click)="goToNotam($event)">
      <div mat-card-avatar class="card-header-image-notam"></div>
      <mat-card-title>Notam</mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-card class="link-card">
    <mat-card-header (click)="goToMetoParaPente($event)">
      <div mat-card-avatar class="card-header-image-notam"></div>
      <mat-card-title>Meteo Parapente</mat-card-title>
    </mat-card-header>
  </mat-card>
</div>

