<div class="reset">
  <div class="container" >
    <div class="title" style="margin-bottom: 3%">
      <h3>Stel nieuw wachtwoord in</h3>
    </div>
    <div class="panel-body" style="padding-bottom: 2em">
      <form class="form-layout" [formGroup]="form" (ngSubmit)="resetPassword()">
        <mat-form-field>
          <input matInput placeholder="Email" formControlName="email" required>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Password" formControlName="password" required>
        </mat-form-field>

        <button mat-raised-button type="submit" [disabled]="!form.valid">Bevestig</button>
      </form>
    </div>
  </div>
  <div class="pic">
</div>

