<div class="container" style="margin-top: 15px">

  <fieldset class="errors" *ngIf="errors?.length" style="margin-right: 5px; margin-bottom: 15px">
    <legend>Ontbrekende informatie!</legend>
    <div *ngFor="let error of errors">
      <a>{{error}}</a>
    </div>
  </fieldset>

  <div class="fields">
    <form class="search" [formGroup]="registerForm">
      <fieldset style="margin-right: 5px">
        <legend>Locatie toevoegen</legend>

        <div>
          <mat-form-field>
            <input matInput required [pattern]="patternLongitude" placeholder="lengtegraad (00.0000)"
                   formControlName="longitude">
          </mat-form-field>

          <mat-form-field>
            <input matInput required [pattern]="patternLatitude" placeholder="breedtegraad ((0)00.0000)"
                   formControlName="latitude">
          </mat-form-field>

          <mat-form-field>
            <mat-select required placeholder="terreintype" formControlName="terreinType">
              <mat-option *ngFor="let type of terreinTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput required formControlName="emailGemeente" placeholder="email gemeente">
          </mat-form-field>

        </div>

        <div>
          <mat-form-field>
            <input matInput placeholder="straatnaam" formControlName="streetName">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="nummer" formControlName="number">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="postcode" formControlName="postalCode">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="gemeente" formControlName="city">
          </mat-form-field>
        </div>

        <div>
          <button mat-raised-button [disabled]="registerForm.invalid" style="margin-right: 3px" (click)="addTerrein()">
            Opslaan
          </button>
          <button color="accent" mat-raised-button (click)="reset()">Nieuw</button>
        </div>

      </fieldset>
    </form>
  </div>

  <div style="margin-top: 10px" class="result" *ngIf="searchResults?.length">

    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="streetName">
          <th mat-header-cell *matHeaderCellDef> Straat</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.streetName}} </td>
        </ng-container>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> Nummer</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.number}} </td>
        </ng-container>

        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef> Postcode</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.postalCode}} </td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> Gemeente</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.city}} </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef> Lengtegraad</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.longitude}} </td>
        </ng-container>

        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef> Breedtegraad</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.latitude}} </td>
        </ng-container>

        <ng-container matColumnDef="terreinType">
          <th mat-header-cell *matHeaderCellDef> Terrein type</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.terreinType}} </td>
        </ng-container>

        <ng-container matColumnDef="emailGemeente">
          <th mat-header-cell *matHeaderCellDef> Email gemeente</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.emailGemeente}} </td>
        </ng-container>

        <ng-container matColumnDef="aangemeld">
          <th mat-header-cell *matHeaderCellDef> Aangemeld</th>
          <td mat-cell *matCellDef="let terrein">
            <mat-icon *ngIf="terrein.aangemeld" color="primary">thumb_up</mat-icon>
            <mat-icon *ngIf="!terrein.aangemeld" color="warn">thumb_down</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="aangemeldOp">
          <th mat-header-cell *matHeaderCellDef> Aangemeld op</th>
          <td mat-cell *matCellDef="let terrein"> {{terrein.aangemeldOp}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let element;">
            <div class="actions">
              <button color="primary" mat-raised-button (click)="terreinAanmelden(element)">Aanmelden</button>
              <mat-icon color="warn" style="cursor: pointer" (click)="deleteTerrein(element)">remove_circle</mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>

  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>

