import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../../model/user";
import {AdminService} from "../../../../../../service/admin.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {LoginService} from "../../../../../../service/login.service";
import {License} from "../../../../../model/license";
import * as moment from "moment";
import {TokenStorageService} from "../../../../../../service/token-storage.service";

@Component({
  selector: 'app-vergunning-details',
  templateUrl: './vergunning-details.component.html',
  styleUrls: ['./vergunning-details.component.scss']
})
export class VergunningDetailsComponent implements OnInit {

  @Input()
  userDetails: User;

  loading: boolean = false;

  displayedColumns = ['license', 'uitgifteDatum', 'vervalDatum', 'nr', 'opmerkingen', 'madeBy', 'actionsColumn']
  possibleLicenses: string[] = ['OPLEIDING', 'PILOOT-SOLO', 'PILOOT-DUO', 'MONITOR', 'FAI', 'EXAMINATOR'];

  registerForm: FormGroup;

  constructor(private adminService: AdminService,
              private loginService: LoginService,
              private tokenStorageService: TokenStorageService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      id: [''],
      licence: [''],
      uitgifteDatum: [''],
      vervalDatum: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      remarks: [''],
      lidNr: [this.userDetails?.lidNr],
      name: [this.userDetails?.firstName],
      surname: [this.userDetails?.surName]
    });
  }

  updateUser(): void {
    this.loading = true;
    this.adminService.getFullMember(this.userDetails.lidNr).subscribe((res) => {
      this.loading = false;
      this.userDetails = res;

      this.registerForm = this.formBuilder.group({
        id: [''],
        licence: [''],
        uitgifteDatum: [''],
        vervalDatum: [''],
        adjustedBy: [this.tokenStorageService.getUser().username],
        remarks: [''],
        lidNr: [this.userDetails?.lidNr],
        name: [this.userDetails?.firstName],
        surname: [this.userDetails?.surName]
      });

      this.toastr.success("Vergunning bijgewerkt!")
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message);
    })
  }

  deleteLicense(element: any) {
    this.adminService.deleteVergunning(element.id).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  addLicense() {
    const newLicense: License = this.registerForm.value;
    this.adminService.addVergunning(newLicense).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
  }

  reset() {
    this.registerForm = this.formBuilder.group({
      id: [''],
      licence: [''],
      uitgifteDatum: [''],
      vervalDatum: [''],
      adjustedBy: [this.tokenStorageService.getUser().username],
      remarks: [''],
      lidNr: [this.userDetails?.lidNr],
      name: [this.userDetails?.firstName],
      surname: [this.userDetails?.surName]
    });
  }
}
