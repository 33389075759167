<div class="container">
  <div class="form-container">
    <form class="form" [formGroup]="logBookForm">
      <div class="flybook-details">
        <fieldset style="margin-right: 5px">
          <legend>Nieuwe vlucht</legend>
          <div class="vlucht">

            <mat-form-field>
              <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Datum" formControlName="date">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Plaats" formControlName="place">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Hoogte (meter)" formControlName="height">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Tijd (minuten)" formControlName="minutes">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Afstand" formControlName="distance">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Materiaal" formControlName="material">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Opmerkingen" formControlName="remarks">
            </mat-form-field>

          </div>
        </fieldset>
        <div style="margin-top: 10px; display: flex; justify-content: flex-end">
          <button mat-raised-button style="margin-right: 3px" (click)="saveLogBook()">Opslaan</button>
          <button color="accent" mat-raised-button (click)="reset()">Reset</button>
        </div>
      </div>
    </form>
    <form class="form" [formGroup]="searchFlyBookForm" (ngSubmit)="searchFlybooks()">
      <div class="flybook-details">
        <fieldset style="margin-right: 5px">
          <legend>Zoek vlucht</legend>
          <div class="vlucht">

            <mat-form-field>
              <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Datum" formControlName="date">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Plaats" formControlName="place">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Materiaal" formControlName="material">
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Opmerkingen" formControlName="remarks">
            </mat-form-field>
          </div>
        </fieldset>
        <div style="margin-top: 10px; display: flex; justify-content: flex-end">
          <button mat-raised-button type="submit">Zoeken</button>
        </div>
      </div>

    </form>
  </div>
  <div class="search-result" style="margin-top: 10px" *ngIf="searchResults.length">
    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Datum</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.date}} </td>
        </ng-container>

        <ng-container matColumnDef="place">
          <th mat-header-cell *matHeaderCellDef> Plaats</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.place}} </td>
        </ng-container>

        <ng-container matColumnDef="height">
          <th mat-header-cell *matHeaderCellDef> Hoogte</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.height}} </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef> Tijd</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.minutes}} </td>
        </ng-container>

        <ng-container matColumnDef="distance">
          <th mat-header-cell *matHeaderCellDef> Afstand</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.distance}} </td>
        </ng-container>

        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef> Materiaal</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.material}} </td>
        </ng-container>

        <ng-container matColumnDef="remarks">
          <th mat-header-cell *matHeaderCellDef> Opmerkingen</th>
          <td mat-cell *matCellDef="let flybook"> {{flybook.remarks}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie </th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon color="warn" style="cursor: pointer" (click)="deleteFlyBook(element)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>
  </div>
</div>


<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>


