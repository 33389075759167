<div *ngIf="isMember" class="container">
  <div>
    <button mat-raised-button (click)="download()">Download</button>
  </div>

  <div id="lidkaart">
    <img id="image1" style="position: relative;" src="assets/images/card-front.jpg" alt="..." width=375/>

    <div style="width: 200px; height: 1000px; position: relative;">
      <img id="image2" style="position: relative;" src="assets/images/card-back.jpg" alt="..." width=375/>
      <img id="image3" style="position: absolute; top: 135px; left: 247px;" [src]="qrcode" alt="..." width=84px/>
    </div>
  </div>

</div>

<div *ngIf="!isMember">
  <img id="image4" style="position: relative;" src="assets/images/card-front-na.jpg" alt="..." width=375/>

  <div style="width: 200px; height: 1000px; position: relative;">
    <img id="image5" style="position: relative;" src="assets/images/card-back-na.jpg" alt="..." width=375/>
  </div>
</div>


