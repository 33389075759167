import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {PasswordService} from "../../service/password.service";
import {LoginService} from "../../service/login.service";
import {HttpClient} from "@angular/common/http";
import {TokenStorageService} from "../../service/token-storage.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  hide = true;
  isLoggedIn = false;
  isLoginFailed = false;
  roles: string[] = [];

  constructor(private loginService: LoginService,
              private tokenStorage: TokenStorageService,
              private passwordService: PasswordService,
              private router: Router,
              private http: HttpClient,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  login() {
    this.loginService.login(
         this.form.controls.username.value,
         this.form.controls.password.value
    ).subscribe((data) => {
      this.tokenStorage.saveToken(data.token);
      this.tokenStorage.saveUser(data);

      this.isLoginFailed = false;
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.router.navigate(['dashboard']);
    }, (err) => {
      this.form.controls.username.setValue('');
      this.form.controls.password.setValue('');
      this.toastr.error(err.message);
    });

  }
}
