import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SearchFiche} from "../components/model/search-fiche";
import {SearchScherm} from "../components/model/search-scherm";
import {SearchFlybook} from "../components/model/search-flybook";

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  constructor() {
  }

  createQueryParamsFiche(searchCriteria: SearchFiche): HttpParams {
    let pms = new HttpParams();

    if (searchCriteria.lidNr) {
      pms = pms.append('lidNr', `${searchCriteria.lidNr}`);
    }

    if (searchCriteria.email && searchCriteria.email !== '') {
      pms = pms.append('email', `${searchCriteria.email}`);
    }

    if (searchCriteria.surName && searchCriteria.surName !== '') {
      pms = pms.append('surName', `${searchCriteria.surName}`);
    }

    if (searchCriteria.firstName && searchCriteria.firstName !== '') {
      pms = pms.append('firstName', `${searchCriteria.firstName}`);
    }

    if (searchCriteria.year && searchCriteria.year !== '') {
      pms = pms.append('year', `${searchCriteria.year}`);
    }

    if (searchCriteria.typePilot && searchCriteria.typePilot !== '') {
      pms = pms.append('typePilot', `${searchCriteria.typePilot}`);
    }

    return pms;
  }

  createQueryParamsScherm(searchCriteria: SearchScherm): HttpParams {
    let pms = new HttpParams();

    if (searchCriteria.brand) {
      pms = pms.append('brand', `${searchCriteria.brand}`);
    }

    if (searchCriteria.type && searchCriteria.type !== '') {
      pms = pms.append('type', `${searchCriteria.type}`);
    }

    if (searchCriteria.primaryColor && searchCriteria.primaryColor !== '') {
      pms = pms.append('primaryColor', `${searchCriteria.primaryColor}`);
    }

    if (searchCriteria.otherColor && searchCriteria.otherColor !== '') {
      pms = pms.append('otherColor', `${searchCriteria.otherColor}`);
    }

    if (searchCriteria.info && searchCriteria.info !== '') {
      pms = pms.append('info', `${searchCriteria.info}`);
    }

    if (searchCriteria.reclame && searchCriteria.reclame !== '') {
      pms = pms.append('reclame', `${searchCriteria.reclame}`);
    }

    if (searchCriteria.idNumber && searchCriteria.idNumber !== '') {
      pms = pms.append('idNumber', `${searchCriteria.idNumber}`);
    }

    return pms;
  }

  createQueryParamsFlybook(searchCriteria: SearchFlybook): HttpParams {
    let pms = new HttpParams();

    if (searchCriteria.lidNr) {
      pms = pms.append('lidNr', `${searchCriteria.lidNr}`);
    }

    if (searchCriteria.date) {
      pms = pms.append('date', `${searchCriteria.date}`);
    }

    if (searchCriteria.material) {
      pms = pms.append('material', `${searchCriteria.material}`);
    }

    if (searchCriteria.remarks) {
      pms = pms.append('remarks', `${searchCriteria.remarks}`);
    }

    if (searchCriteria.place) {
      pms = pms.append('place', `${searchCriteria.place}`);
    }

    return pms;
  }
}
