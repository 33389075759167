import {Component, OnInit} from "@angular/core";
import {AdminService} from "../../../service/admin.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {QrCodeData} from "../../model/qr-code-data";
import {environment} from "../../../../environments/environment";
import QRCode from 'qrcode';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  user: QrCodeData;
  avatar: any;
  year: number;
  now: Date;
  qrcode: any;

  constructor(private adminService: AdminService,
              private domSanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.now = new Date();
    this.year = this.now.getFullYear();

    const lidNr = this.activatedRoute.snapshot.paramMap.get("lidnr");
    const guid = this.activatedRoute.snapshot.paramMap.get("guid");

    this.adminService.getQrCodeData(lidNr, guid).subscribe(res => {
      this.user = res;

      const url = environment.url + '/qr/' + lidNr + '/' + guid;

      QRCode.toDataURL(url)
        .then(url => {
          this.qrcode = url;
        })
        .catch(err => {
          console.error(err)
        })

      this.adminService.getImage(+lidNr).subscribe(res => {
        if (res) {
          this.avatar = this.convertAvatarToImage(res.picture);
        }
      })
    })
  }

  convertAvatarToImage(input: any): any {
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + input);
  }
}
