import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticationGuard} from './components/guard/authentication.guard';
import {ToastrModule} from 'ngx-toastr';
import {LoginModule} from './components/login/login.module';
import {AppRoutingModule} from "./components/module/app-routing.module";
import {RequestResetPasswordModule} from "./components/request-reset-password/request-reset-password.module";
import {ResetPasswordModule} from "./components/reset-password/reset-password.module";
import {DashboardModule} from "./components/dashboard/dashboard.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {authInterceptorProviders} from "./interceptor/auth.interceptor";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RequestLidnrModule} from "./components/request-lidnr/request-lidnr.module";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RequestLidnrModule,
    HttpClientModule,
    ReactiveFormsModule,
    LoginModule,
    FlexLayoutModule,
    ResetPasswordModule,
    DashboardModule,
    RequestResetPasswordModule,
    MatMomentDateModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true
    }),
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [AuthenticationGuard, authInterceptorProviders],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
