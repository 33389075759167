<div class="container" style="margin-top: 15px">

  <form class="search" [formGroup]="registerForm">
    <fieldset style="margin-right: 5px">
      <legend>Airmiles toevoegen</legend>
      <div>
        <mat-form-field>
          <input matInput type="number" placeholder="airmiles" formControlName="airmiles">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="info" formControlName="airmilesInfo">
        </mat-form-field>

        <button style="margin-right: 3px" mat-raised-button (click)="addAirmile()">Opslaan</button>
        <button color="accent" mat-raised-button (click)="reset()">Reset</button>
      </div>
    </fieldset>
  </form>

  <div style="margin-top: 10px" *ngIf="userDetails.airmiles.length" class="result">

    <div>
      <table mat-table [dataSource]="userDetails.airmiles" class="mat-elevation-z8">

        <ng-container matColumnDef="airmiles">
          <th mat-header-cell *matHeaderCellDef> Airmiles</th>
          <td mat-cell *matCellDef="let airmile"> {{airmile.airmiles}} </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef> Info</th>
          <td mat-cell *matCellDef="let airmile"> {{airmile.airmilesInfo}} </td>
        </ng-container>

        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef> Jaar</th>
          <td mat-cell *matCellDef="let airmile"> {{airmile.airmilesYear}} </td>
        </ng-container>

        <ng-container matColumnDef="madeBy">
          <th mat-header-cell *matHeaderCellDef> Gemaakt door</th>
          <td mat-cell *matCellDef="let airmile"> {{airmile.adjustedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell *matHeaderCellDef> Aanmaakdatum</th>
          <td mat-cell *matCellDef="let airmile"> {{airmile.dateAdjusted}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon color="warn" style="cursor: pointer" (click)="deleteAirmile(element)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
