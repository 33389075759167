import {Component, OnInit} from '@angular/core';
import {PasswordService} from '../../service/password.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(private passwordService: PasswordService,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  resetPasswordEmail() {
    this.passwordService.requestResetPassword(this.form.controls.email.value).subscribe(() => {

      this.router.navigate(['login']);
      this.toastr.success('Email met instructies verzonden!');
    }, (err) => this.toastr.error(err.message));
  }
}
