<form *ngIf="user" class="form" [formGroup]="profileForm">

  <div class="person-details">
    <fieldset>
      <legend>Persoonsgegevens</legend>
      <div class="person">
        <mat-form-field>
          <input matInput readonly placeholder="lidNr" formControlName="lidNr">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="naam" formControlName="surName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="voornaam" formControlName="firstName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Geboortedatum(yyyy-mm-dd)" formControlName="birthDate">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Geboorteplaats" formControlName="birthPlace">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>

        <mat-form-field>
          <input matInput mask="00.00.00-000.00" placeholder="Rrn" formControlName="rrn">
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="paramotorType" placeholder="Type">
            <mat-option [value]=""> - </mat-option>
            <mat-option *ngFor="let brand of possibleParamotors" [value]="brand">
              {{brand}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </fieldset>

    <fieldset>
      <legend>Foto</legend>
      <div class="person">
        <div>
          <img [src]="avatar" *ngIf="avatar" style="border-radius: 20px; border: 1px solid black" width="171"
               height="200px">
          <img src="assets/images/unknown.jpg" *ngIf="!avatar" style="border-radius: 15px; border: 1px solid black"
               width="171" height="200px">
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-evenly; margin-top: 10px">
          <input
            style="display: none"
            type="file" accept="image/*" (change)="onFileChanged($event)"
            #fileInput>
          <button mat-raised-button style="margin-right: 10px" (click)="fileInput.click()">Zoeken</button>
          <button mat-raised-button (click)="onUpload()">Opslaan</button>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Medische keuring</legend>
      <div class="person">
        <div>
          <mat-form-field>
            <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Gekeurd op(dd-mm-yyyy)" formControlName="medicalCert">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-select formControlName="medicalCertYearsValid" placeholder="Geldigheidsduur">
              <mat-option [value]="2"> 2 jaar </mat-option>
              <mat-option [value]="5"> 5 jaar </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </div>

  <div style="margin-top: 10px;">
    <fieldset>
      <legend>Address</legend>
      <div class="address">
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput placeholder="Straat" formControlName="street">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Nr" formControlName="nr">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Postcode" formControlName="postalCode">
          </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput placeholder="Gemeente" formControlName="municipal">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Land" formControlName="country">
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </div>

  <div style="margin-top: 10px;">
    <fieldset>
      <legend>Contact</legend>
      <div class="numbers">
        <mat-form-field>
          <input matInput placeholder="Tel" formControlName="telNumber">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="GSM" formControlName="gsmNumber">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="ICE" formControlName="iceNumber">
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset style="margin-top: 10px;">
      <legend>Privacy</legend>
      <div class="permissions">
        <div>
          <mat-checkbox [checked]="profileForm?.controls['permissionAddress'].value"
                        formControlName="permissionAddress">Toon address
          </mat-checkbox>
          <mat-checkbox [checked]="profileForm?.controls['permissionTelNumber'].value"
                        formControlName="permissionTelNumber">Toon tel
          </mat-checkbox>
          <mat-checkbox [checked]="profileForm?.controls['permissionGsmNumber'].value"
                        formControlName="permissionGsmNumber">Toon GSM
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox [checked]="profileForm?.controls['permissionIceNumber'].value"
                        formControlName="permissionIceNumber">Toon ICE
          </mat-checkbox>
          <mat-checkbox [checked]="profileForm?.controls['permissionEmail'].value" formControlName="permissionEmail">
            Toon email
          </mat-checkbox>
          <mat-checkbox [checked]="profileForm?.controls['permissionSchermen'].value"
                        formControlName="permissionSchermen">Toon schermen
          </mat-checkbox>
        </div>
      </div>
    </fieldset>

  </div>

  <div style="margin-top: 10px; display: flex; justify-content: flex-end">
    <button mat-raised-button color="accent" (click)="saveProfile()" [disabled]="profileForm.invalid">Opslaan</button>
  </div>

</form>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>

