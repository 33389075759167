import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import {TerreinAanmelding} from "../../../model/terrein-aanmelding";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xls';

@Component({
  selector: 'app-aanmeldingen',
  templateUrl: './aanmeldingen.component.html',
  styleUrls: ['./aanmeldingen.component.scss']
})
export class AanmeldingenComponent implements OnInit {

  displayedColumns = ['aangemeldOp', 'lidNr', 'naam', 'gemeente', 'longitude', 'latitude']

  searchResults: TerreinAanmelding[] = [];
  searchForm: FormGroup;

  loading: boolean = false;

  constructor(private adminService: AdminService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      aangemeldVan: [''],
      aangemeldTot: ['']
    });
  }

  searchAanmeldingen() {
    this.loading = true

    this.adminService.getAanmeldingen(this.searchForm.get('aangemeldVan').value, this.searchForm.get('aangemeldTot').value).subscribe(res => {
      this.searchResults = res
      this.loading = false;

      if (res?.length === 0) {
        this.toastr.warning("Geen resultaten gevonden!")
      }
    }, error => {
      this.toastr.error(error.error.message)
      this.loading = false;
    })
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'data': worksheet
      },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  downloadAanmeldingen() {
    this.loading = true
    this.exportAsExcelFile(this.searchResults, 'aanmeldingen');
    this.loading = false;
  }
}
