import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../model/user";
import {PublicService} from "../../../../service/public.service";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import {TokenStorageService} from "../../../../service/token-storage.service";
import * as dateDiffer from "date-differ";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  possibleParamotors: string[] = ['TRIKE', 'DORSAAL', 'DORSAAL & TRIKE']

  selectedFile: File
  loading: boolean = false;
  avatar: any;

  fileName = '';

  user: User;
  profileForm: FormGroup;

  constructor(private publicService: PublicService,
              private domSanitizer: DomSanitizer,
              private tokenStorageService: TokenStorageService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    const lidNr = this.tokenStorageService.getUser().username;

    this.publicService.getFullMember(lidNr).subscribe((user) => {
      this.user = user;
      this.profileForm = this.formBuilder.group({
        lidNr: [user.lidNr, Validators.required],
        surName: [user.surName, Validators.required],
        firstName: [user.firstName, Validators.required],
        street: [user.street, Validators.required],
        nr: [user.nr, Validators.required],
        postalCode: [user.postalCode, Validators.required],
        municipal: [user.municipal, Validators.required],
        country: [user.country, Validators.required],
        birthDate: [user.birthDate, Validators.required],
        birthPlace: [user.birthPlace, Validators.required],
        email: [user.email, [Validators.required, Validators.email]],
        telNumber: [user.telNumber],
        gsmNumber: [user.gsmNumber],
        iceNumber: [user.iceNumber],
        permissionAddress: [user.permissionAddress],
        permissionEmail: [user.permissionEmail],
        permissionTelNumber: [user.permissionTelNumber],
        permissionGsmNumber: [user.permissionGsmNumber],
        permissionSchermen: [user.permissionSchermen],
        permissionIceNumber: [user.permissionIceNumber],
        paramotorType: [user.paramotorType],
        medicalCert: [user.medicalCert],
        medicalCertYearsValid: [user.medicalCertYearsValid],
        rrn: [user.rrn, Validators.required]
      });

      this.publicService.getImage(this.user.lidNr).subscribe(res => {
        if (res) {
          this.avatar = this.convertAvatarToImage(res.picture);
        }
      });

      if (this.user.medicalCert && this.user.medicalCertYearsValid) {
        let strings: string[] = this.user.medicalCert.split("-");

        let fullYear = +strings[0] + this.user.medicalCertYearsValid;

        let expirationDate = new Date(fullYear, +strings[1] - 1, +strings[2])
        let today: Date = new Date();

        let result: string;

        if (today < expirationDate) {
          result = dateDiffer({
            from: today,
            to: expirationDate,
            days: true
          });
        } else {
          result = dateDiffer({
            from: expirationDate,
            to: today,
            days: true
          });
        }

        let results = result.split(" ");

        if (+results[0] <= 60) {
          this.toastr.warning("Medische keuring vervalt bijna. Hernieuw deze tijdig!")
        }
      }
    })
  }

  updateUser() {
    this.loading = true;
    this.publicService.updateUser(this.user).subscribe((res) => {
      this.loading = false;
      this.user = res;

      this.publicService.getImage(this.user.lidNr).subscribe(res => {
        if (res) {
          this.avatar = this.convertAvatarToImage(res.picture);
        }

        this.toastr.success("Lid bijgewerkt!")
      }, error => {
        this.loading = false;
        this.toastr.success(error.message);
      })
    })
  }

  convertAvatarToImage(input: any): any {
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + input);
  }

  saveProfile() {
    const user: User = this.profileForm.value;
    this.publicService.updateUser(user).subscribe(res => {
      this.user = user;
      this.toastr.success("Wijzigingen opgeslagen!")
    }, error => this.toastr.error(error.error.message))
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (event) => {
      this.avatar = event.target.result;
    }
  }

  onUpload() {
    const uploadData = new FormData();
    uploadData.append('pic', this.selectedFile, this.selectedFile.name);
    this.publicService.saveImage(this.user.lidNr, uploadData).subscribe(() => {
      this.updateUser();
      this.toastr.success("Foto opgeslagen")
    }, () => this.toastr.error("Fout bij opslaan foto!"))
  }
}
