<div class="container">

  <div class="search">

    <div>
      <mat-form-field>
        <mat-select (selectionChange)="selectYear($event)" placeholder="jaar">
          <mat-option [value]="0"> - </mat-option>
          <mat-option *ngFor="let year of possibleYears" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <button mat-raised-button (click)="searchAirmiles()">Zoek</button>
    </div>

  </div>

  <div class="result" *ngIf="searchResults.length">

    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="lidNr">
          <th mat-header-cell *matHeaderCellDef> Lid nr.</th>
          <td mat-cell *matCellDef="let airmiles"> {{airmiles.lidNr}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> Voornaam</th>
          <td mat-cell *matCellDef="let airmiles"> {{airmiles.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surName">
          <th mat-header-cell *matHeaderCellDef> Naam</th>
          <td mat-cell *matCellDef="let airmiles"> {{airmiles.surName}} </td>
        </ng-container>

        <ng-container matColumnDef="airmiles">
          <th mat-header-cell *matHeaderCellDef> Airmiles</th>
          <td mat-cell *matCellDef="let airmiles"> {{airmiles.airmiles}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </div>

</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
