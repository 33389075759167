import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {User} from "../components/model/user";
import {Airmiles} from "../components/model/airmiles";
import {SearchFiche} from "../components/model/search-fiche";
import {Fiche} from "../components/model/fiche";
import {QueryBuilderService} from "./query-builder.service";
import {Scherm} from "../components/model/scherm";
import {SearchScherm} from "../components/model/search-scherm";
import {PmsaMember} from "../components/model/pmsa-member";
import {License} from "../components/model/license";
import {Image} from "../components/model/image";
import {QrCodeData} from "../components/model/qr-code-data";
import {Lidkaart} from "../components/model/lidkaart";
import {AviabelUser} from "../components/model/aviabel-user";
import * as moment from "moment";
import {TerreinAanmelding} from "../components/model/terrein-aanmelding";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient,
              private queryBuilderService: QueryBuilderService) {
  }

  public getEmails(): Observable<string[]> {
    return this.http.get<string[]>(environment.backend + "/admin/email-listing");
  }

  public createUser(newUser: User): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/add-user", newUser);
  }

  public userExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(environment.backend + "/admin/exists/" + email);
  }

  public updateUser(updatedUser: User): Observable<any> {
    return this.http.put<any>(environment.backend + "/admin/member/update", updatedUser);
  }

  public loadYearsAirmiles(): Observable<string[]> {
    return this.http.get<string[]>(environment.backend + "/admin/airmiles/years");
  }

  public getAirmiles(selectedYear: number): Observable<Airmiles[]> {
    return this.http.get<Airmiles[]>(environment.backend + "/admin/airmiles/year/" + selectedYear);
  }

  public loadYearsFiche(): Observable<string[]> {
    return this.http.get<string[]>(environment.backend + "/admin/fiche/years");
  }

  public getFiche(searchCriteria: SearchFiche): Observable<Fiche[]> {
    return this.http.get<Fiche[]>(environment.backend + "/admin/fiche", {params: this.queryBuilderService.createQueryParamsFiche(searchCriteria)});
  }

  public getBrandSchermen(): Observable<string[]> {
    return this.http.get<string[]>(environment.backend + "/admin/scherm/brands");
  }

  public getSchermen(searchCriteria: SearchScherm): Observable<Scherm[]> {
    return this.http.get<Scherm[]>(environment.backend + "/admin/scherm", {params: this.queryBuilderService.createQueryParamsScherm(searchCriteria)});
  }

  public deleteMember(lidNr: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/admin/member/" + lidNr);
  }

  public deleteScherm(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/admin/member/scherm/" + id);
  }

  public deleteAirmiles(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/admin/member/airmiles/" + id);
  }

  public deleteVergunning(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/admin/member/license/" + id);
  }

  public deleteVerzekering(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/admin/member/verzekering/" + id);
  }

  public addScherm(scherm: Scherm): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/member/scherm", scherm);
  }

  public addVergunning(license: License): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/member/license/", license);
  }

  public addVerzekering(pmsaMember: PmsaMember): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/member/verzekering/", pmsaMember);
  }

  public addAirmiles(airmiles: Airmiles): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/member/airmiles/", airmiles);
  }

  public getFullMember(lidNr: number): Observable<User> {
    return this.http.get<User>(environment.backend + "/admin/member/" + lidNr);
  }

  public saveImage(lidNr: number, image: FormData): Observable<any> {
    return this.http.post<any>(environment.backend + "/admin/member/image/" + lidNr, image);
  }

  public getImage(lidNr: number): Observable<Image> {
    return this.http.get<Image>(environment.backend + "/admin/member/image/" + lidNr);
  }

  public getQrCodeData(lidNr: string, guid: string): Observable<QrCodeData> {
    return this.http.get<QrCodeData>(environment.backend + "/admin/member/" + lidNr + "/qr/" + guid);
  }

  public getLidKaart(lidNr: string): Observable<Lidkaart> {
    return this.http.get<Lidkaart>(environment.backend + "/admin/member/" + lidNr + "/valid/");
  }

  public getAttest(lidNr: string): Observable<User> {
    return this.http.get<User>(environment.backend + "/admin/member/" + lidNr + "/attest/");
  }

  public getAviabelUsers(aangeslotenVan: string, aangeslotenTot: string): Observable<AviabelUser[]> {
    let pms = new HttpParams();

    if (aangeslotenVan) {
      pms = pms.append('van', `${aangeslotenVan}`);
    }

    if (aangeslotenTot) {
      pms = pms.append('tot', `${aangeslotenTot}`);
    } else {

      pms = pms.append('tot', `${moment(new Date).format("DD-MM-YYYY")}`);
    }

    return this.http.get<AviabelUser[]>(environment.backend + "/admin/aviabel", {params: pms});
  }

  public getAanmeldingen(aangeslotenVan: string, aangeslotenTot: string): Observable<TerreinAanmelding[]> {
    let pms = new HttpParams();

    if (aangeslotenVan) {
      pms = pms.append('van', `${aangeslotenVan}`);
    }

    if (aangeslotenTot) {
      pms = pms.append('tot', `${aangeslotenTot}`);
    } else {

      pms = pms.append('tot', `${moment(new Date).format("DD-MM-YYYY")}`);
    }

    return this.http.get<TerreinAanmelding[]>(environment.backend + "/admin/aanmeldingen", {params: pms});
  }

  updateMember(pmsaMember: PmsaMember) {
    return this.http.put<any>(environment.backend + "/admin/member/verzekering/", pmsaMember);
  }
}
