import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {PasswordReset} from "../components/model/password-reset";

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private http: HttpClient) {
  }

  requestResetPassword(email: string): Observable<any> {
    return this.http.post<any>(environment.backend + '/password/request-reset/' + email, {
      withCredentials: false
    })
  }

  resetPassword(passwordReset: PasswordReset): Observable<any> {
    return this.http.post(environment.backend + '/password/reset', passwordReset, {
      withCredentials: false
    });
  }
}
