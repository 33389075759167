import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  goToMeteo($event: MouseEvent) {
    window.open('https://www.meteo.be/nl/', '_blank')
  }

  goToPmsa($event: MouseEvent) {
    window.open('https://www.pmsafed.be/', '_blank')
  }

  goToNotam($event: MouseEvent) {
    window.open('https://ops.skeyes.be/opersite/notamsummary.do?cmd=summaryToHtml', '_blank')
  }

  goToMetoParaPente($event: MouseEvent) {
      window.open('https://meteo-parapente.com/#/', '_blank')
  }
}
