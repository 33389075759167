import {Component, OnInit} from "@angular/core";
import {AdminService} from "../../../service/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-lidkaart',
  templateUrl: './lidkaart.component.html',
  styleUrls: ['./lidkaart.component.scss']
})
export class LidkaartComponent implements OnInit {

  isMember: boolean;
  qrcode: any;

  constructor(private adminService: AdminService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const lidNr = this.activatedRoute.snapshot.paramMap.get("lidnr");
    const guid = this.activatedRoute.snapshot.paramMap.get("guid");

    this.adminService.getLidKaart(lidNr).subscribe( res => {

      if(!res) {
        this.isMember = false;
      } else {
        this.isMember = true;

        const url = environment.url + '/qr/' + lidNr + '/' + guid;

        QRCode.toDataURL(url)
          .then(url => {
            this.qrcode = url;
          })
          .catch(err => {
            console.error(err)
          })
      }
    })
  }

  download() {
    const data = document.getElementById('lidkaart');
    html2canvas(data).then(canvas => {
      const imgWidth = 104;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('lidkaart.pdf');
    });
  }
}
