import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../../../service/admin.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  emails: string[];
  emails$: Observable<string[]> = this.adminService.getEmails();

  constructor(private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.emails$.subscribe(res => this.emails = res)
  }
}
