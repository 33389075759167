import {Component} from '@angular/core';

@Component({
  selector: 'app-overlay-spinner',
  template: `
    <div>
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="75"></mat-progress-spinner>
      <p>Even geduld ...</p>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, .5);
    }
  `]
})
export class OverlaySpinnerComponent {
}
