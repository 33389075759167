import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PublicService} from "../../../../service/public.service";
import {TokenStorageService} from "../../../../service/token-storage.service";
import {ToastrService} from "ngx-toastr";
import {LoginService} from "../../../../service/login.service";
import {Terrein} from "../../../model/terrein";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Aanmelding} from "../../../model/aanmelding";

@Component({
  selector: 'app-terrain',
  templateUrl: './terrain.component.html',
  styleUrls: ['./terrain.component.scss']
})
export class TerrainComponent implements OnInit {

  patternLongitude = new RegExp('^(\\+|-)?(?:90(?:(?:\\.0{4})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{4})?))$');
  patternLatitude = new RegExp('^(\\+|-)?(?:180(?:(?:\\.0{4})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{4})?))$');

  loading: boolean = false;

  terreinTypes: string[] = ["Recreatief", "Tijdelijk"];
  paramotorTypes: string[] = ["MONO", "DUO", "BEIDE"];
  displayedColumns = ['streetName', 'number', 'postalCode', 'city', 'latitude', 'longitude', 'terreinType', "emailGemeente", 'aangemeld', 'aangemeldOp', 'actionsColumn']

  registerForm: FormGroup;
  aanmeldingForm: FormGroup;
  selectedTerrein: Terrein;

  searchResults: Terrein[] = [];
  lidNr: number;
  errors: string[];

  constructor(private publicService: PublicService,
              private tokenStorageService: TokenStorageService,
              private toastr: ToastrService,
              private loginService: LoginService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.lidNr = this.tokenStorageService.getUser().username;

    this.registerForm = this.formBuilder.group({
      id: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      streetName: [''],
      number: [''],
      postalCode: [''],
      city: [''],
      terreinType: ['', Validators.required],
      lidNr: [this.lidNr],
      aangemeld: [''],
      aangemeldOp: [''],
      emailGemeente: ['', [Validators.email, Validators.required]]
    });
    this.aanmeldingForm = this.formBuilder.group({
      allowed: [false, Validators.required],
      paramotor: ['', Validators.required],
      startDate: ['', Validators.required],
      terrein: ['', Validators.required]
    });

    this.getTerreinen();

    this.publicService.validateTerrein(this.lidNr).subscribe(val => {
      this.errors = val.errors;
    })
  }

  deleteTerrein(element: any) {
    this.publicService.deleteTerrein(element.id).subscribe(() => {
      this.getTerreinen();
      this.toastr.success("Locatie verwijderd!")
    }, error => this.toastr.error(error.error.message))
  }

  addTerrein() {
    const terrein: Terrein = this.registerForm.value;
    this.publicService.addTerrein(terrein).subscribe(() => {
      this.getTerreinen();
      this.toastr.success("Locatie aangemaakt!");
    }, error => this.toastr.error(error.error.message))
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
    this.selectedTerrein = this.registerForm.value;
    this.aanmeldingForm.get('terrein').patchValue(this.registerForm.value);
  }

  reset() {
    this.selectedTerrein = undefined;
    this.registerForm = this.formBuilder.group({
      id: [''],
      latitude: [''],
      longitude: [''],
      streetName: [''],
      number: [''],
      postalCode: [''],
      city: [''],
      terreinType: [''],
      lidNr: [this.lidNr],
      aangemeld: [''],
      aangemeldOp: [''],
      emailGemeente: ['', [Validators.email, Validators.required]]
    });
    this.aanmeldingForm = this.formBuilder.group({
      allowed: [false],
      paramotor: ['', Validators.required],
      startDate: ['', Validators.required],
      terrein: ['', Validators.required]
    });
  }

  private getTerreinen() {
    this.publicService.getTerreinen(this.lidNr).subscribe(terreinen => this.searchResults = terreinen);
  }

  terreinAanmelden(element: Terrein) {
    let dialogRef = this.dialog.open(TerreinDialogComponent, {
      width: '350px',
      data: {paramotorTypes: this.paramotorTypes}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result) {
        return;
      }

      this.loading = true;
      const aanmelding = result as Aanmelding;
      aanmelding.terrein = element;

      this.publicService.verzendAanmelding(this.lidNr, aanmelding)
        .subscribe(() => {
          this.getTerreinen();
          this.toastr.success("Aanmelding geslaagd!");
          this.loading = false;
          this.reset();
        }, (err) => {
          this.loading = false;
          this.toastr.error(err.error.message);
        })
    });
  }
}


@Component({
  selector: 'terrein-dialog',
  templateUrl: 'terrein-dialog.component.html',
})
export class TerreinDialogComponent {

  allowed: boolean = false;
  paramotor: any;
  startDate: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TerreinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    const form = this.fb.group({
      allowed: [this.allowed],
      paramotor: [this.paramotor],
      startDate: [this.startDate]
    });
    this.dialogRef.close(form.value)
  }


}
