import {Component, OnInit} from '@angular/core';
import {LoginService} from "../../../../service/login.service";
import {User} from "../../../model/user";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../../../service/token-storage.service";
import {PublicService} from "../../../../service/public.service";

@Component({
  selector: 'app-insurance',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  user: User;

  lidNr: any;

  constructor(private loginService: LoginService,
              private publicService: PublicService,
              private tokenStorageService: TokenStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.lidNr = this.tokenStorageService.getUser().username;
    this.publicService.getFullMember(this.lidNr).subscribe( res => this.user = res);
  }

  showLidkaart() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/lidkaart/' + this.lidNr + "/" + this.user.guid]));
    window.open(url, '_blank');
  }

  showAttest() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/attest/' + this.user.lidNr]));
    window.open(url, '_blank');
  }
}
