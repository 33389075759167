import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment";
import {PublicService} from "../../../../service/public.service";
import {Flybook} from "../../../model/flybook";
import {SearchFlybook} from "../../../model/search-flybook";
import {TokenStorageService} from "../../../../service/token-storage.service";

@Component({
  selector: 'app-flybook',
  templateUrl: './flybook.component.html',
  styleUrls: ['./flybook.component.scss']
})
export class FlybookComponent implements OnInit {

  displayedColumns = ['date', 'place', 'height', 'time', 'distance', 'material', 'remarks', 'actionsColumn']

  loading: boolean = false;
  logBookForm: FormGroup;
  searchFlyBookForm: FormGroup;

  lidNr: any;

  searchResults: Flybook[] = [];

  constructor(private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private tokenStorageService: TokenStorageService,
              private publicService: PublicService) {
  }

  ngOnInit(): void {
    this.lidNr = this.tokenStorageService.getUser().username;

    this.logBookForm = this.formBuilder.group({
      id: [''],
      height: [''],
      date: [''],
      minutes: [''],
      distance: [''],
      material: [''],
      place: [''],
      remarks: [''],
      lidNr: [this.lidNr]
    });

    this.searchFlyBookForm = this.formBuilder.group({
      date: [''],
      material: [''],
      place: [''],
      remarks: [''],
      lidNr: [this.lidNr]
    });
  }

  saveLogBook() {
    if (this.logBookForm.valid) {
      this.loading = true;
      const flybook: Flybook = this.logBookForm.value;

      this.publicService.addFlight(flybook).subscribe(() => {
        this.loading = false;

        this.logBookForm = this.formBuilder.group({
          id: [''],
          height: [''],
          date: [''],
          minutes: [''],
          distance: [''],
          material: [''],
          place: [''],
          remarks: [''],
          lidNr: [this.lidNr]
        });

        if (this.searchResults.length > 0) {
          let searchFlybook = new SearchFlybook();
          searchFlybook.lidNr = this.lidNr;
          this.publicService.searchFlybooks(searchFlybook).subscribe((res) => {
            this.loading = false;
            this.searchResults = res;
          }, error => {
            this.loading = false;
            this.toastr.error(error.error.message)
          })
        }

        this.toastr.success("Vlucht toegevoegd!")
      }, error => {
        this.loading = false;
        this.toastr.error(error.error.message)
      })

    }

  }

  searchFlybooks() {
    this.loading = true;
    const searchFlybook: SearchFlybook = this.searchFlyBookForm.value;
    this.publicService.searchFlybooks(searchFlybook).subscribe((res) => {
      this.loading = false;
      this.searchResults = res;

      if(res.length === 0) {
        this.toastr.warning("Geen zoekresultaten gevonden");
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message)
    })
  }

  deleteFlyBook(element) {
    this.publicService.deleteFlybook(element.id).subscribe(() => this.searchFlybooks(), error => this.toastr.error(error.error.message))
  }

  useElement(row) {
    this.logBookForm = this.formBuilder.group(row);
  }

  reset() {
    this.logBookForm = this.formBuilder.group({
      id: [''],
      height: [''],
      date: [''],
      minutes: [''],
      distance: [''],
      material: [''],
      place: [''],
      remarks: [''],
      lidNr: [this.lidNr]
    });
  }
}
