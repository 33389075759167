<h1 mat-dialog-title>Terrein aanmelding</h1>
<div mat-dialog-content>
    <div>
      <mat-form-field>
        <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false"
               [specialCharacters]="['-']" placeholder="datum(dd-mm-yyyy)" [(ngModel)]="startDate">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-select required placeholder="type" [(ngModel)]="paramotor">
          <mat-option *ngFor="let type of data?.paramotorTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
        <mat-checkbox [(ngModel)]="allowed">
          Toestemming
        </mat-checkbox>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Terug</button>
  <button mat-raised-button color="primary" [disabled]="!paramotor || !startDate" (click)="save()">Verzenden</button>
</div>
