<div class="container" style="margin-top: 15px">

  <form class="search" [formGroup]="registerForm">
    <fieldset style="margin-right: 5px">
      <legend>Scherm toevoegen</legend>
      <div>
        <mat-form-field>
          <mat-select formControlName="merk" placeholder="merk">
            <mat-option [value]=""> -</mat-option>
            <mat-option *ngFor="let brand of possibleBrands" [value]="brand">
              {{brand}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="type" formControlName="type">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="info" formControlName="info">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="m2" formControlName="m2">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <input matInput placeholder="reclame" formControlName="reclame">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="kenteken" formControlName="idNumber">
        </mat-form-field>

        <mat-form-field>
          <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Keuringsdatum(dd-mm-yyyy)" formControlName="keuringsDate">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-select formControlName="primaryColor" placeholder="hoofdkleur">
            <mat-option [value]=""> -</mat-option>
            <mat-option *ngFor="let color of colors" [value]="color">
              {{color}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="secondaryColor" placeholder="2° kleur">
            <mat-option [value]=""> -</mat-option>
            <mat-option *ngFor="let color of colors" [value]="color">
              {{color}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="tertiaryColor" placeholder="3° kleur">
            <mat-option [value]=""> -</mat-option>
            <mat-option *ngFor="let color of colors" [value]="color">
              {{color}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-raised-button style="margin-right: 3px" (click)="addScherm()">Opslaan</button>
        <button color="accent" mat-raised-button (click)="reset()">Reset</button>

      </div>
    </fieldset>
  </form>

  <div style="margin-top: 10px" class="result" *ngIf="currentUser?.schermList?.length">

    <div>
      <table mat-table [dataSource]="currentUser.schermList" class="mat-elevation-z8">

        <ng-container matColumnDef="idNumber">
          <th mat-header-cell *matHeaderCellDef> Kenteken</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.idNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="merk">
          <th mat-header-cell *matHeaderCellDef> Merk</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.merk}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.type}} </td>
        </ng-container>

        <ng-container matColumnDef="primaryColor">
          <th mat-header-cell *matHeaderCellDef> Hoofdkleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.primaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="secondaryColor">
          <th mat-header-cell *matHeaderCellDef> 2° kleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.secondaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="tertiaryColor">
          <th mat-header-cell *matHeaderCellDef> 3° kleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.tertiaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="m2">
          <th mat-header-cell *matHeaderCellDef> m2</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.m2}} </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef> Info</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.info}} </td>
        </ng-container>

        <ng-container matColumnDef="reclame">
          <th mat-header-cell *matHeaderCellDef> Reclame</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.reclame}} </td>
        </ng-container>

        <ng-container matColumnDef="registrationDate">
          <th mat-header-cell *matHeaderCellDef> Gekeurd op</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.keuringsDate}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon color="warn" style="cursor: pointer" (click)="deleteScherm(element)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
