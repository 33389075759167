import {Component, OnInit} from '@angular/core';
import {PasswordService} from '../../service/password.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(private passwordService: PasswordService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(this.route.snapshot.queryParamMap.get('email')),
      token: new FormControl(this.route.snapshot.queryParamMap.get('token')),
      password: new FormControl('')
    });
  }

  resetPassword() {
    this.passwordService.resetPassword(this.form.value).subscribe(() => {
      this.router.navigate(['login']);
      this.toastr.success('Wachtwoord met succes gewijzigd!');
    }, () => this.toastr.error('Wachtwoord kon niet gewijzigd worden.'));
  }

}
