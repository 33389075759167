<div class="container">

  <form class="search" [formGroup]="searchForm">

    <div>

      <mat-form-field>
        <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" required  placeholder="Aangesloten van" formControlName="aangeslotenVan">
      </mat-form-field>

      <mat-form-field>
        <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']"  placeholder="Aangesloten tot" formControlName="aangeslotenTot">
      </mat-form-field>

    </div>

    <div>
      <button mat-raised-button style="margin-right: 1.5px" (click)="searchAviabelUsers()">Zoek</button>
      <button mat-raised-button *ngIf="searchResults?.length" (click)="downloadAviabelUsers()">Download</button>
    </div>

  </form>

  <div class="result" *ngIf="searchResults?.length">

    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="naam">
          <th mat-header-cell *matHeaderCellDef> Naam</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.naam}} </td>
        </ng-container>

        <ng-container matColumnDef="voornaam">
          <th mat-header-cell *matHeaderCellDef> Voornaam</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.voornaam}} </td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef> Straat</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.straat}} </td>
        </ng-container>

        <ng-container matColumnDef="nr">
          <th mat-header-cell *matHeaderCellDef> Nr</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.nr}} </td>
        </ng-container>

        <ng-container matColumnDef="postcode">
          <th mat-header-cell *matHeaderCellDef> Postcode</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.postcode}} </td>
        </ng-container>

        <ng-container matColumnDef="municipal">
          <th mat-header-cell *matHeaderCellDef> Gemeente</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.municipal}} </td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef> Land</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.country}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.type}} </td>
        </ng-container>

        <ng-container matColumnDef="aanmaakDatum">
          <th mat-header-cell *matHeaderCellDef> Aangesloten</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.aanmaakDatum}} </td>
        </ng-container>

        <ng-container matColumnDef="verzonden">
          <th mat-header-cell *matHeaderCellDef> Verzonden</th>
          <td mat-cell *matCellDef="let aviabel"> {{aviabel.monthSend}} - {{aviabel.yearSend}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
