<div class="container">
  <div class="header">
    <img src="../../../assets/images/my_pmsa.jpg" width="20%" height="10%" style="min-width: 324px">
  </div>

  <div class="dashboard">
    <div class="menu">
      <ul>
        <li [routerLink]="['profile']">Mijn profiel</li>
        <li [routerLink]="['documents']">Documenten</li>
        <li [routerLink]="['schermen']">Schermen</li>
        <li [routerLink]="['flybook']">Logboek</li>
        <li [routerLink]="['terrain']" style="margin-bottom: 3em">Mijn terreinen</li>

        <li [routerLink]="['info']" style="margin-bottom: 3em">Nuttige links</li>

        <li [routerLink]="['add-member']" *ngIf="isAdmin">Lid toevoegen</li>
        <li [routerLink]="['search-fiche']" *ngIf="isAdmin">Fiche zoeken</li>
        <li [routerLink]="['airmiles']" *ngIf="isAdmin">Airmiles</li>
        <li [routerLink]="['aanmeldingen']" *ngIf="isAdmin">Aanmeldingen</li>
        <li [routerLink]="['search-scherm']" *ngIf="isAdmin">Scherm zoeken</li>
        <li [routerLink]="['aviabel-user']" *ngIf="isAdmin">Aviabel</li>
        <li [routerLink]="['email']" *ngIf="isAdmin">Email leden</li>
        <li (click)="logout()">Afmelden</li>
      </ul>
    </div>

    <div class="router-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>


