<div class="flybook-details">

    <div style="margin-bottom: 10px">
      <button mat-raised-button color="warn" (click)="showLidkaart()">Lidkaart</button>
    </div>

    <div>
      <button mat-raised-button color="warn" (click)="showAttest()">Verzekeringsattest</button>
    </div>

</div>
