import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {User} from "../components/model/user";
import {Scherm} from "../components/model/scherm";
import {Image} from "../components/model/image";
import {Flybook} from "../components/model/flybook";
import {SearchFlybook} from "../components/model/search-flybook";
import {QueryBuilderService} from "./query-builder.service";
import {RequestLidNr} from "../components/model/request-lidnr";
import {Terrein} from "../components/model/terrein";
import {TerreinValidatie} from "../components/model/terrein-validatie";
import {Aanmelding} from "../components/model/aanmelding";


@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private http: HttpClient,
              private queryBuilderService: QueryBuilderService) {
  }

  public updateUser(user: User): Observable<User> {
    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + sessionStorage.getItem('token')
    });

    let options = {headers: headers};

    return this.http.put<User>(environment.backend + "/public/user/change", user, options);
  }

  public addScherm(newScherm: Scherm) {
    return this.http.post<any>(environment.backend + "/public/member/scherm", newScherm);
  }

  public saveImage(lidNr: number, image: FormData): Observable<any> {
    return this.http.post<any>(environment.backend + "/public/member/image/" + lidNr, image);
  }

  public getImage(lidNr: number): Observable<Image> {
    return this.http.get<Image>(environment.backend + "/public/member/image/" + lidNr);
  }

  public getLidNr(email: string): Observable<RequestLidNr> {
    return this.http.get<RequestLidNr>(environment.backend + "/public/user/lidnr/" + email);
  }

  public getFullMember(lidNr: number): Observable<User> {
    return this.http.get<User>(environment.backend + "/public/member/" + lidNr);
  }

  public deleteScherm(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/public/member/scherm/" + id);
  }

  addFlight(flybook: Flybook): Observable<any> {
    return this.http.post<any>(environment.backend + "/public/member/flybook/", flybook);
  }

  searchFlybooks(searchFlybook: SearchFlybook): Observable<Flybook[]> {
    return this.http.get<Flybook[]>(environment.backend + "/public/member/flybook/search", {params: this.queryBuilderService.createQueryParamsFlybook(searchFlybook)});
  }

  deleteFlybook(id) {
    return this.http.delete<any>(environment.backend + "/public/member/flybook/" + id);
  }

  public deleteTerrein(id: number): Observable<any> {
    return this.http.delete<any>(environment.backend + "/public/member/terrein/" + id);
  }

  public addTerrein(terrein: Terrein): Observable<any> {
    return this.http.post<any>(environment.backend + "/public/member/terrein", terrein);
  }

  public updateScherm(terrein: Terrein): Observable<any> {
    return this.http.put<any>(environment.backend + "/public/member/terrein", terrein);
  }

  public getTerreinen(lidNr: number): Observable<Terrein[]> {
    return this.http.get<Terrein[]>(environment.backend + "/public/member/terrein/" + lidNr);
  }

  public validateTerrein(lidNr: number): Observable<TerreinValidatie> {
    return this.http.get<TerreinValidatie>(environment.backend + "/public/member/terrein-aanmelding/validate/" + lidNr);
  }

  public verzendAanmelding(lidNr: number, aanmelding: Aanmelding): Observable<any> {
    return this.http.post<any>(environment.backend + "/public/member/aanmelding/" + lidNr, aanmelding);
  }
}
