import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import {MatSelectChange} from "@angular/material/select";
import {Airmiles} from "../../../model/airmiles";

@Component({
  selector: 'app-airmiles',
  templateUrl: './airmiles.component.html',
  styleUrls: ['./airmiles.component.scss']
})
export class AirmilesComponent implements OnInit {

  loading: boolean = false;

  displayedColumns = ['lidNr', 'firstName', 'surName', 'airmiles']

  selectedYear: number = 0;
  possibleYears: string[] = [];
  searchResults: Airmiles[] = [];

  constructor(private adminService: AdminService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.adminService.loadYearsAirmiles()
      .subscribe(res => this.possibleYears = res, error => this.toastr.error(error.error.message));
  }

  searchAirmiles() {
    this.loading = true;
    this.adminService.getAirmiles(this.selectedYear)
      .subscribe(res => {
        this.searchResults = res;
        this.loading = false;

        if (res.length === 0) {
          this.toastr.warning("Geen zoekresultaten gevonden");
        }

      }, error => {
        this.toastr.error(error.error.message)
        this.loading = false;
      })
  }

  selectYear($event: MatSelectChange) {
    this.selectedYear = $event.value;
  }
}
