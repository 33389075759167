import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../../model/user";
import {AdminService} from "../../../../../../service/admin.service";
import {Scherm} from "../../../../../model/scherm";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-schermen-details',
  templateUrl: './schermen-details.component.html',
  styleUrls: ['./schermen-details.component.scss']
})
export class SchermenDetailsComponent implements OnInit {

  @Input()
  userDetails: User;

  loading: boolean = false;

  displayedColumns = ['merk', 'idNumber', 'type', 'primaryColor', 'secondaryColor', 'tertiaryColor', 'm2', 'info', 'reclame', 'registrationDate', 'actionsColumn']
  colors = ['BLAUW', 'BRUIN', 'GEEL', 'GRIJS', 'GROEN', 'ORANJE', 'ROOD', 'VIOLET', 'WIT', 'ZWART']

  possibleBrands: string[] = [];

  registerForm: FormGroup;

  constructor(private adminService: AdminService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.adminService.getBrandSchermen().subscribe(res => this.possibleBrands = res);

    this.registerForm = this.formBuilder.group({
      id: [''],
      merk: [''],
      type: [''],
      primaryColor: [''],
      secondaryColor: [''],
      tertiaryColor: [''],
      info: [''],
      m2: [''],
      reclame: [''],
      keuringsDate: [''],
      idNumber: [''],
      lidNr: [this.userDetails?.lidNr],
      name: [this.userDetails?.firstName],
      surname: [this.userDetails?.surName]
    });
  }

  updateUser(): void {
    this.loading = true;
    this.adminService.getFullMember(this.userDetails.lidNr).subscribe((res) => {
      this.loading = false;
      this.userDetails = res;

      this.registerForm = this.formBuilder.group({
        id: [''],
        merk: [''],
        type: [''],
        primaryColor: [''],
        secondaryColor: [''],
        tertiaryColor: [''],
        info: [''],
        m2: [''],
        keuringsDate: [''],
        reclame: [''],
        idNumber: [''],
        lidNr: [this.userDetails?.lidNr],
        name: [this.userDetails?.firstName],
        surname: [this.userDetails?.surName]
      });

      this.toastr.success("Scherm bijgewerkt!")
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message);
    })
  }

  deleteScherm(element: any) {
    this.adminService.deleteScherm(element.id).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  addScherm() {
    const newScherm: Scherm = this.registerForm.value;
    this.adminService.addScherm(newScherm).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
  }

  reset() {
    this.registerForm = this.formBuilder.group({
      id: [''],
      merk: [''],
      type: [''],
      primaryColor: [''],
      secondaryColor: [''],
      tertiaryColor: [''],
      info: [''],
      m2: [''],
      reclame: [''],
      keuringsDate: [''],
      idNumber: [''],
      lidNr: [this.userDetails?.lidNr],
      name: [this.userDetails?.firstName],
      surname: [this.userDetails?.surName]
    });
  }
}
