<form class="form" [formGroup]="profileForm" (ngSubmit)="saveProfile(formDirective)" #formDirective="ngForm">

  <div class="person-details">
    <fieldset style="margin-right: 5px">
      <legend>Nieuw lid</legend>
      <div class="person">

        <div style="display: flex; flex-direction: column; margin-right: 5px">
          <mat-form-field>
            <input matInput placeholder="Voornaam" formControlName="firstName">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Achternaam" formControlName="surName">
          </mat-form-field>

          <mat-form-field>
            <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false"
                   [specialCharacters]="['-']" placeholder="Geboortedatum(dd-MM-yyyy)" formControlName="birthDate">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Geboorteplaats" formControlName="birthPlace">
          </mat-form-field>

          <mat-form-field>
            <input matInput type="email" placeholder="Email" formControlName="email">
          </mat-form-field>

          <mat-form-field>
            <input matInput mask="00.00.00-000.00" placeholder="Rijksregisternummer" formControlName="rrn">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Tel" formControlName="telNumber">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="GSM" formControlName="gsmNumber">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="ICE" formControlName="iceNumber">
          </mat-form-field>

          <mat-checkbox formControlName="bestuursLid">Bestuurslid</mat-checkbox>
        </div>
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput placeholder="Straat" formControlName="street">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Nr" formControlName="nr">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Postcode" formControlName="postalCode">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Gemeente" formControlName="municipal">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Land" formControlName="country">
          </mat-form-field>
        </div>

      </div>
    </fieldset>

    <div style="margin-top: 10px; display: flex; justify-content: flex-end">
      <button mat-raised-button [disabled]="profileForm.invalid" type="submit">Opslaan</button>
    </div>

  </div>
</form>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>


