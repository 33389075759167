import {Component, OnInit} from '@angular/core';
import {Scherm} from "../../../model/scherm";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {PublicService} from "../../../../service/public.service";
import {LoginService} from "../../../../service/login.service";
import {User} from "../../../model/user";
import {AdminService} from "../../../../service/admin.service";
import {TokenStorageService} from "../../../../service/token-storage.service";

import * as dateDiffer from "date-differ";

@Component({
  selector: 'app-schermen',
  templateUrl: './schermen.component.html',
  styleUrls: ['./schermen.component.scss']
})
export class SchermenComponent implements OnInit {

  loading: boolean = false;

  displayedColumns = ['merk', 'idNumber', 'type', 'primaryColor', 'secondaryColor', 'tertiaryColor', 'm2', 'info', 'reclame', 'registrationDate', 'actionsColumn']
  colors = ['BLAUW', 'BRUIN', 'GEEL', 'GRIJS', 'GROEN', 'ORANJE', 'ROOD', 'VIOLET', 'WIT', 'ZWART']

  possibleBrands: string[] = [];
  registerForm: FormGroup;

  searchResults: Scherm[] = [];
  currentUser: User;

  constructor(private publicService: PublicService,
              private adminService: AdminService,
              private tokenStorageService: TokenStorageService,
              private toastr: ToastrService,
              private loginService: LoginService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const lidNr = this.tokenStorageService.getUser().username;
    this.publicService.getFullMember(lidNr).subscribe((user) => {
      this.currentUser = user;

      this.currentUser.schermList.forEach(scherm => {

        if(scherm.keuringsDate) {
          let strings: string[] = scherm.keuringsDate.split("-");

          let fullYear = +strings[0] + 2;

          let expirationDate = new Date(fullYear, +strings[1] - 1, +strings[2])
          let today:Date = new Date();

          let result: string;

          if(today < expirationDate) {
            result = dateDiffer({
              from: today,
              to: expirationDate,
              days: true
            });
          } else {
            result = dateDiffer({
              from: expirationDate,
              to: today,
              days: true
            });
          }

          let results = result.split(" ");

          if (+results[0] <= 60) {
            this.toastr.warning("Scherm keuring vervalt bijna. Hernieuw deze tijdig!")
          }
        }
      });

    });

    this.adminService.getBrandSchermen().subscribe(res => this.possibleBrands = res);

    this.registerForm = this.formBuilder.group({
      id: [''],
      merk: [''],
      type: [''],
      primaryColor: [''],
      secondaryColor: [''],
      tertiaryColor: [''],
      info: [''],
      m2: [''],
      reclame: [''],
      idNumber: [''],
      keuringsDate: [''],
      lidNr: [lidNr],
      name: [this.currentUser?.firstName],
      surname: [this.currentUser?.surName]
    });
  }

  updateUser(): void {
    this.loading = true;
    this.publicService.getFullMember(this.currentUser.lidNr).subscribe((res) => {
      this.loading = false;
      this.currentUser = res;

      this.registerForm = this.formBuilder.group({
        id: [''],
        merk: [''],
        type: [''],
        primaryColor: [''],
        secondaryColor: [''],
        tertiaryColor: [''],
        info: [''],
        m2: [''],
        reclame: [''],
        idNumber: [''],
        keuringsDate: [''],
        lidNr: [this.currentUser?.lidNr],
        name: [this.currentUser?.firstName],
        surname: [this.currentUser?.surName]
      });

      this.searchResults = this.currentUser.schermList;

      this.toastr.success("Scherm bijgewerkt!")
    }, error => {
      this.loading = false;
      this.toastr.error(error.error.message);
    })
  }

  deleteScherm(element: any) {
    this.publicService.deleteScherm(element.id).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  addScherm() {
    const newScherm: Scherm = this.registerForm.value;
    this.publicService.addScherm(newScherm).subscribe(() => this.updateUser(), error => this.toastr.error(error.error.message))
  }

  useElement(row) {
    this.registerForm = this.formBuilder.group(row);
  }

  reset() {
    this.registerForm = this.formBuilder.group({
      id: [''],
      merk: [''],
      type: [''],
      primaryColor: [''],
      secondaryColor: [''],
      tertiaryColor: [''],
      info: [''],
      m2: [''],
      reclame: [''],
      idNumber: [''],
      keuringsDate: [''],
      lidNr: [this.currentUser?.lidNr],
      name: [this.currentUser?.firstName],
      surname: [this.currentUser?.surName]
    });
  }
}
