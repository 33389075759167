<div class="container" style="margin-top: 15px">

  <form class="search" [formGroup]="registerForm">
    <fieldset style="margin-right: 5px">
      <legend>Verzekering toevoegen/bewerken</legend>

      <div>
        <mat-form-field>
          <mat-select formControlName="year" placeholder="Jaar" required>
            <mat-option [value]=0> - </mat-option>
            <mat-option *ngFor="let year of possibleYears" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="verzekeringBpmf" placeholder="Verzekering PMSA" (selectionChange)="updateLidgeld($event)">
            <mat-option [value]="'GEEN'"> - </mat-option>
            <mat-option *ngFor="let verz of possibleVerzekeringen" [value]="verz">
              {{verz}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" placeholder="Lidgeld" formControlName="lidGeld">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" placeholder="Premie" formControlName="verzekeringsPremie">
        </mat-form-field>

        <mat-form-field>
          <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Geldig vanaf" formControlName="aanmaakDatum">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <input matInput placeholder="Verzekering andere" formControlName="verzekeringAndere">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Andere polis nr" formControlName="verzekeringAnderePolisNr">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Opmerkingen" formControlName="remark">
        </mat-form-field>

        <mat-checkbox formControlName="payed">Betaald</mat-checkbox>
      </div>
      <div>
        <button style="margin-right: 3px" mat-raised-button [disabled]="registerForm.invalid" (click)="addVerzekering()">Opslaan</button>
        <button color="accent" mat-raised-button (click)="reset()">Reset</button>
      </div>
    </fieldset>
  </form>

  <div style="margin-top: 10px" *ngIf="userDetails.pmsaMemberList.length" class="result">

    <div>
      <table mat-table [dataSource]="userDetails.pmsaMemberList" class="mat-elevation-z8">
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef> Jaar</th>
          <td mat-cell *matCellDef="let member"> {{member.year}} </td>
        </ng-container>

        <ng-container matColumnDef="lidgeld">
          <th mat-header-cell *matHeaderCellDef> Lidgeld</th>
          <td mat-cell *matCellDef="let member"> {{member.lidGeld}} </td>
        </ng-container>

        <ng-container matColumnDef="verzekering_pmsa">
          <th mat-header-cell *matHeaderCellDef> Verzekering PMSA</th>
          <td mat-cell *matCellDef="let member"> {{member.verzekeringBpmf}} </td>
        </ng-container>

        <ng-container matColumnDef="premie">
          <th mat-header-cell *matHeaderCellDef> Premie</th>
          <td mat-cell *matCellDef="let member"> {{member.verzekeringsPremie}} </td>
        </ng-container>

        <ng-container matColumnDef="verzekering_andere">
          <th mat-header-cell *matHeaderCellDef> Verzekering andere</th>
          <td mat-cell *matCellDef="let member"> {{member.verzekeringAndere}} </td>
        </ng-container>

        <ng-container matColumnDef="polis_nr">
          <th mat-header-cell *matHeaderCellDef> Polis nr</th>
          <td mat-cell *matCellDef="let member"> {{member.verzekeringAnderePolisNr}} </td>
        </ng-container>

        <ng-container matColumnDef="remarks">
          <th mat-header-cell *matHeaderCellDef> Opmerkingen</th>
          <td mat-cell *matCellDef="let member"> {{member.remark}} </td>
        </ng-container>

        <ng-container matColumnDef="madeBy">
          <th mat-header-cell *matHeaderCellDef> Gemaakt door</th>
          <td mat-cell *matCellDef="let member"> {{member.adjustedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="dateValid">
          <th mat-header-cell *matHeaderCellDef> Geldig vanaf</th>
          <td mat-cell *matCellDef="let member"> {{member.aanmaakDatum}} </td>
        </ng-container>

        <ng-container matColumnDef="payed">
          <th mat-header-cell *matHeaderCellDef> Betaald</th>
          <td mat-cell *matCellDef="let member">
            <mat-icon *ngIf="member.payed" color="primary">thumb_up</mat-icon>
            <mat-icon *ngIf="!member.payed" color="warn">thumb_down</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="attest">
          <th mat-header-cell *matHeaderCellDef> Attest</th>
          <td mat-cell *matCellDef="let member;">
            <button mat-raised-button color="primary" *ngIf="showAttestButton(member)" (click)="showVerzekeringsAttest(member)">Verzekeringsattest</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let member;">
            <mat-icon color="primary" style="cursor: pointer" (click)="updateVerzekering(member)">save</mat-icon>
            <mat-icon color="warn" style="cursor: pointer" (click)="deleteVerzekering(member)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
