<div class="container">

  <form class="search" [formGroup]="searchForm">

    <div>
      <mat-form-field>
        <mat-select formControlName="brand" placeholder="merk">
          <mat-option [value]=""> - </mat-option>
          <mat-option *ngFor="let brand of possibleBrands" [value]="brand">
            {{brand}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="type" formControlName="type">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="info" formControlName="info">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="reclame" formControlName="reclame">
      </mat-form-field>

    </div>

    <div>
      <mat-form-field>
        <mat-select formControlName="primaryColor" placeholder="hoofdkleur">
          <mat-option [value]=""> - </mat-option>
          <mat-option *ngFor="let color of colors" [value]="color">
            {{color}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="otherColor" placeholder="andere kleur">
          <mat-option [value]=""> - </mat-option>
          <mat-option *ngFor="let color of colors" [value]="color">
            {{color}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="kenteken" formControlName="idNumber">
      </mat-form-field>

      <button mat-raised-button (click)="searchScherm()">Zoek</button>

    </div>

  </form>

  <div class="result" *ngIf="searchResults?.length">

    <div>
      <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">

        <ng-container matColumnDef="lidNr">
          <th mat-header-cell *matHeaderCellDef> Lid nr.</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.lidNr}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> Voornaam</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surName">
          <th mat-header-cell *matHeaderCellDef> Naam</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.surName}} </td>
        </ng-container>

        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef> Merk</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.brand}} </td>
        </ng-container>

        <ng-container matColumnDef="idNumber">
          <th mat-header-cell *matHeaderCellDef> Kenteken</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.idNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.type}} </td>
        </ng-container>

        <ng-container matColumnDef="primaryColor">
          <th mat-header-cell *matHeaderCellDef> Hoofdkleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.primaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="secondaryColor">
          <th mat-header-cell *matHeaderCellDef> 2° kleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.secondaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="tertiaryColor">
          <th mat-header-cell *matHeaderCellDef> 3° kleur</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.tertiaryColor}} </td>
        </ng-container>

        <ng-container matColumnDef="m2">
          <th mat-header-cell *matHeaderCellDef> m2</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.m2}} </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef> Info</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.info}} </td>
        </ng-container>

        <ng-container matColumnDef="reclame">
          <th mat-header-cell *matHeaderCellDef> Reclame</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.reclame}} </td>
        </ng-container>

        <ng-container matColumnDef="registrationDate">
          <th mat-header-cell *matHeaderCellDef> Registratie</th>
          <td mat-cell *matCellDef="let scherm"> {{scherm.registrationDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
