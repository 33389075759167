import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import {Fiche} from "../../../model/fiche";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchFiche} from "../../../model/search-fiche";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ConfirmationDialogComponent} from "../dialog/confirmation-dialog.component";
import {User} from "../../../model/user";

@Component({
  selector: 'app-search-fiche',
  templateUrl: './search-fiche.component.html',
  styleUrls: ['./search-fiche.component.scss']
})
export class SearchFicheComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['lidNr', 'firstName', 'surName', 'street', 'nr', 'postalCode', 'municipal', 'country', 'email', 'actionsColumn']

  loading: boolean = false;
  showDetails: boolean = false;

  dataSource;

  possibleYears: string[] = [];
  searchResults: Fiche[] = [];

  searchForm: FormGroup;

  userDetails: User;

  constructor(private adminService: AdminService,
              private toastr: ToastrService,
              private router: Router,
              private dialog: MatDialog,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.showDetails = false;

    this.adminService.loadYearsFiche()
      .subscribe(res => this.possibleYears = res, error => this.toastr.error(error.error.message));

    this.dataSource = new MatTableDataSource(this.searchResults);

    this.searchForm = this.formBuilder.group({
      lidNr: [],
      firstName: [],
      surName: [],
      year: [],
      email: [],
      typePilot: []
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  searchFiche() {
    this.showDetails = false;
    this.loading = true;
    const searchCriteria: SearchFiche = this.searchForm.value;

    this.adminService.getFiche(searchCriteria).subscribe(res => {
      this.searchResults = res
      this.dataSource = new MatTableDataSource(this.searchResults);
      this.dataSource.sort = this.sort;
      this.loading = false;

      if (res?.length === 0) {
        this.toastr.warning("Geen resultaten gevonden!")
      }
    }, error => {
      this.toastr.error(error.error.message)
      this.loading = false;
    })
  }

  openDialog(fiche: Fiche) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Bent u zeker dat u lidnr ${fiche.lidNr} wilt verwijderen?`,
        buttonText: {
          ok: 'Ja',
          cancel: 'Nee'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteMember(fiche);
      }
    });
  }

  deleteMember(fiche: Fiche) {
    this.loading = true;

    this.adminService.deleteMember(fiche.lidNr).subscribe(() => {
      this.searchResults.splice(this.searchResults.findIndex(fiche => fiche.lidNr === fiche.lidNr), 1)
      this.dataSource = new MatTableDataSource(this.searchResults);
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this.toastr.error(error.error.message)
      this.loading = false;
    })
  }

  openDetails(fiche: Fiche) {
    this.loading = true;

    this.adminService.getFullMember(fiche.lidNr).subscribe((res) => {
      this.userDetails = res;
      this.showDetails = true;
      this.loading = false;
    }, error => {
      this.toastr.error(error.error.message)
      this.loading = false;
    })

  }

  showQr(fiche: Fiche) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/qr/' + fiche.lidNr + '/' + fiche.guid]));
    window.open(url, '_blank');
  }

  reset() {
    this.searchForm = this.formBuilder.group({
      lidNr: [],
      firstName: [],
      surName: [],
      year: [],
      email: [],
      typePilot: []
    });
  }
}
