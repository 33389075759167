import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../../model/user";
import {AdminService} from "../../../../../../service/admin.service";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'app-gegevens-details',
  templateUrl: './gegevens-details.component.html',
  styleUrls: ['./gegevens-details.component.scss']
})
export class GegevensDetailsComponent implements OnInit {

  @Input()
  userDetails: User;
  selectedFile: File
  loading: boolean = false;
  avatar: any;

  possibleParamotors: string[] = ['TRIKE', 'DORSAAL', 'DORSAAL & TRIKE']

  constructor(private adminService: AdminService,
              private domSanitizer: DomSanitizer,
              private router: Router,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    if (this.userDetails) {
      this.adminService.getImage(this.userDetails.lidNr).subscribe(res => {
        if (res) {
          this.avatar = this.convertAvatarToImage(res.picture);
        }
      })
    }
  }

  convertAvatarToImage(input: any): any {
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + input);
  }

  updateUser() {
    this.loading = true;
    this.adminService.updateUser(this.userDetails).subscribe((res) => {
      this.loading = false;
      this.userDetails = res;

      this.adminService.getImage(this.userDetails.lidNr).subscribe(res => {
        if (res) {
          this.avatar = this.convertAvatarToImage(res.picture);
        }

        this.toastr.success("Lid bijgewerkt!")
      }, error => {
        this.loading = false;
        this.toastr.success(error.message);
      })
    })
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile)

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (event) => {
      this.avatar = event.target.result;
    }
  }

  onUpload() {
    const uploadData = new FormData();
    uploadData.append('pic', this.selectedFile, this.selectedFile.name);
    this.adminService.saveImage(this.userDetails.lidNr, uploadData).subscribe(() => {
      this.updateUser();
      this.toastr.success("Foto opgeslagen")
    }, () => this.toastr.error("Fout bij opslaan foto!"))
  }

  showMemberCard() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/lidkaart/' + this.userDetails.lidNr + "/" + this.userDetails.guid]));
    window.open(url, '_blank');
  }
}
