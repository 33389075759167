<div class="container" style="margin-top: 15px">

  <form class="search" [formGroup]="registerForm">
    <fieldset style="margin-right: 5px">
      <legend>Vergunning toevoegen</legend>
      <div>
        <mat-form-field>
          <mat-select formControlName="licence" placeholder="vergunning">
            <mat-option [value]=""> -</mat-option>
            <mat-option *ngFor="let license of possibleLicenses" [value]="license">
              {{license}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Uitgiftedatum" formControlName="uitgifteDatum">
        </mat-form-field>

        <mat-form-field>
          <input matInput mask="d0-M0-0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" [specialCharacters]="['-']" placeholder="Vervaldatum" formControlName="vervalDatum">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="opmerkingen" formControlName="remarks">
        </mat-form-field>

        <button style="margin-right: 3px" mat-raised-button (click)="addLicense()">Opslaan</button>
        <button color="accent" mat-raised-button (click)="reset()">Reset</button>
      </div>
    </fieldset>
  </form>

  <div style="margin-top: 10px" *ngIf="userDetails.licenses.length" class="result">

    <div>
      <table mat-table [dataSource]="userDetails.licenses" class="mat-elevation-z8">

        <ng-container matColumnDef="license">
          <th mat-header-cell *matHeaderCellDef> Vergunning</th>
          <td mat-cell *matCellDef="let license"> {{license.licence}} </td>
        </ng-container>

        <ng-container matColumnDef="uitgifteDatum">
          <th mat-header-cell *matHeaderCellDef> Uitgiftedatum</th>
          <td mat-cell *matCellDef="let license"> {{license.uitgifteDatum}} </td>
        </ng-container>

        <ng-container matColumnDef="vervalDatum">
          <th mat-header-cell *matHeaderCellDef> Vervaldatum</th>
          <td mat-cell *matCellDef="let license"> {{license.vervalDatum}} </td>
        </ng-container>

        <ng-container matColumnDef="nr">
          <th mat-header-cell *matHeaderCellDef> Nr</th>
          <td mat-cell *matCellDef="let license"> {{license.lidNr}} </td>
        </ng-container>

        <ng-container matColumnDef="opmerkingen">
          <th mat-header-cell *matHeaderCellDef> Opmerkingen</th>
          <td mat-cell *matCellDef="let license"> {{license.remarks}} </td>
        </ng-container>

        <ng-container matColumnDef="madeBy">
          <th mat-header-cell *matHeaderCellDef> Gemaakt door</th>
          <td mat-cell *matCellDef="let license"> {{license.adjustedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
          <th mat-header-cell *matHeaderCellDef> Actie</th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon color="warn" style="cursor: pointer" (click)="deleteLicense(element)">remove_circle</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="useElement(row)"></tr>
      </table>
    </div>
  </div>
</div>

<ng-content *ngIf="!loading; else spinner"></ng-content>
<ng-template #spinner>
  <div>
    <app-overlay-spinner></app-overlay-spinner>
  </div>
</ng-template>
